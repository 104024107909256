import { Edit } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Address } from "../components/address";
import LoadingBox from "../components/loading_box";
import SectionHeader from "../components/section_header";
import SpiningLogo from "../components/spining_logo";
import { TextInput } from "../components/text_input";
import { BranchType } from "../const";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import { getRequest, postRequest } from "../network/api-client";
import { headerActionButtonStyle } from "../styles";

export default function CustomerService() {
  const { handleSubmit, register, reset } = useForm();
  const [isSubmitting, setIsSubimtting] = useState(false);
  const [isHeadOfficeLoading, setIsHeadOfficeLoading] = useState(false);
  const [isResetSafe, setIsResetSafe] = useState(false);
  const [headOffice, setHeadOffice] = useState<BranchType>();
  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    setIsSubimtting(true);
    const res = await postRequest("customer-service", data).finally(() => {
      setIsSubimtting(false);
      setIsResetSafe(true);
    });
    Swal.fire({
      title: res.detail as string,
      text: "",
      icon: "success",
      background: "#fff",
      color: "#000",
      confirmButtonText: t("ok"),
    });
  };

  useEffect(() => {
    if (!isResetSafe) {
      return;
    }
    reset({
      customerName: null,
      email: "",
      mobile: null,
      message: null,
    });
  }, [isResetSafe]);

  const fetchHeadOffice = async () => {
    setIsHeadOfficeLoading(true);
    const res = await getRequest("branches/").finally(() =>
      setIsHeadOfficeLoading(false)
    );
    setHeadOffice(res.branches[0]);
  };

  useEffect(() => {
    fetchHeadOffice();
  }, []);

  return (
    <Box
      sx={{
        // height: "80vh",
        display: "flex",
        flexDirection: "column",
        padding: {
          xs: "3rem 1rem 3rem 1rem",
          sm: "3rem 1rem 3rem 1rem",
          md: "3rem 1rem 3rem 1rem",
          lg: "3rem 5rem 3rem 5rem",
          xl: "3rem 5rem 3rem 5rem",
        },
      }}
    >
      <SectionHeader title={t("customer-service")} />
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
          },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%", lg: "65%" },
            display: "flex",
            flexDirection: "column",
            // p: "1rem 0 3rem 0",
            p: {
              xs: "0 1rem 1rem 1rem",
              sm: "0 1rem 1rem 1rem",
              md: "0 1rem 1rem 1rem",
              lg: "0",
            },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
            {/* <SectionHeader title={t("contact-us")} /> */}

            <Typography gutterBottom variant="h5" component="div">
              {t("contact-us")}
            </Typography>
            <br />
            <TextInput
              title={t("name")}
              errorMessage={""}
              required={true}
              registeration={{ ...register("customerName") }}
            />
            <TextInput
              title={t("email")}
              errorMessage={""}
              required={true}
              type="email"
              registeration={{ ...register("email") }}
            />
            <TextInput
              title={t("mobile-number")}
              errorMessage={""}
              type="number"
              required={true}
              registeration={{ ...register("mobile") }}
            />
            <TextInput
              title={t("message-comment")}
              errorMessage={""}
              required={true}
              height={"120px"}
              multiLine={true}
              rows={5}
              registeration={{ ...register("message") }}
            />
            <br />
            <Button
              fullWidth
              type="submit"
              disabled={isSubmitting}
              variant="outlined"
              size="large"
            >
              {isSubmitting ? <SpiningLogo height="20px" /> : t("submit")}
            </Button>
          </form>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%", lg: "30%" },
          }}
        >
          <SectionHeader
            title={t("head-office")}
            adminActions={[
              <IconButton style={headerActionButtonStyle} onClick={() => {}}>
                <Edit />
              </IconButton>,
            ]}
          />
          {isHeadOfficeLoading ? (
            <LoadingBox />
          ) : (
            headOffice?.longitude !== ("NaN" && undefined) &&
            headOffice?.latitude !== ("NaN" && undefined) && (
              <Address branch={headOffice} />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
}
