import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { MouseEventHandler, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

// { name, path, pathList }
type NavButtonProps = {
  name: string;
  path?: string;
  pathList: string[];
  onButtonClick?: any;
  visiblity?: "hidden" | "visible";
};

const NavButton = (props: NavButtonProps) => {
  const currentPage = useLocation();

  return props.path === currentPage.pathname ||
    props.pathList.filter(
      (pagePath: string) => pagePath === currentPage.pathname
    ).length > 0 ? (
    <Button
      onClick={props.onButtonClick ?? undefined}
      href={props.path ?? undefined}
      sx={{
        mx: "0.5rem",
        fontSize: "14px",
        visibility: props.visiblity,
      }}
      variant="contained"
      // size="medium"
    >
      {props.name}
    </Button>
  ) : (
    <Button
      onClick={props.onButtonClick ?? undefined}
      href={props.path ?? undefined}
      sx={{
        mx: "0.5rem",
        fontSize: "14px",
        visibility: props.visiblity,
      }}
      // size="medium"
    >
      {props.name}
    </Button>
  );
};

export default NavButton;
