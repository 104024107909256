import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import NavButton from "./nav_button";
import { useTranslation } from "react-i18next";
import { SetStateAction, useContext, useState } from "react";
import LocaleContext from "../LocaleContext";
import i18n from "../i18n";
import { isAdmin, TYPE } from "../const";
import ExpertiseLogo from "../logo.svg";
import LanguageSwitcher from "./language_switcher_button";
import {
  Edit,
  KeyboardArrowDown,
  LogoutRounded,
  Menu,
} from "@mui/icons-material";
import PricingDialog from "./pricing_dialog";
import ClaimsDialog from "./claims_dialog";
import StyledMenu from "./styled_menu";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationBar = (props: { window?: () => Window }) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   // navigate("/");
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const { locale, type, setType } = useContext(LocaleContext);
  const { window } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isEn = i18n.language === "en-US";
  const drawerWidth = "50%";

  const headerPages = [
    {
      id: 0,
      value: type === TYPE.INDIVIDUALS ? t("individuals") : t("corporates"),
      path: "/",
      availablePaths: ["/", "home"],
    },
    {
      id: 1,
      value: type === TYPE.INDIVIDUALS ? t("corporates") : t("individuals"),
      path: "/",
      availablePaths: ["/", "home"],
    },
    {
      id: 2,
      value: t("get-a-quote"),
      path: "/admin/pricing-requests",
      availablePaths: ["/admin/pricing-requests"],
    },
    {
      id: 3,
      value: t("file-a-claim"),
      path: "/admin/claims",
      availablePaths: ["/admin/claims"],
    },
    {
      id: 4,
      value: t("customer-service"),
      path: isAdmin ? "/admin/customer-services" : "/customer-service",
      availablePaths: isAdmin
        ? ["/admin/customer-services"]
        : ["/customer-service"],
    },
    {
      id: 5,
      value: t("branches"),
      path: "/branches",
      availablePaths: ["/branches"],
    },
    {
      id: 6,
      value: t("users"),
      path: "/admin/users",
      availablePaths: ["/admin/users"],
    },
  ];

  const individualsMenu = [
    { title: "individual", icon: "icon", path: "/" },
    { title: "individual", icon: "icon", path: "/" },
    { title: "individual", icon: "icon", path: "/" },
  ];

  const corporatesMenu = [
    { title: "corporate", icon: "icon", path: "/" },
    { title: "corporate", icon: "icon", path: "/" },
    { title: "corporate", icon: "icon", path: "/" },
  ];

  const [openPricingDialog, setOpenPricingDialog] = useState(false);
  const [openClaimsDialog, setOpenClaimsDialog] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickOpen = (
    setOpen: (value: SetStateAction<boolean>) => void
  ) => {
    setOpen(true);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleClosePricingDialog = () => {
    setOpenPricingDialog(false);
  };

  const handleCloseClaimsDialog = () => {
    setOpenClaimsDialog(false);
  };

  const switchType = () => {
    if (type === TYPE.CORPORATES) {
      setType(TYPE.INDIVIDUALS);
      sessionStorage.setItem("type", TYPE.INDIVIDUALS);
    } else {
      setType(TYPE.CORPORATES);
      sessionStorage.setItem("type", TYPE.CORPORATES);
    }
    navigate("/");
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // const MenuItems = (
  //   menu: {
  //     title: string;
  //     icon: string;
  //     path: string;
  //   }[]
  // ) => (
  //   <>
  //     {menu.map((item) => (
  //       <MenuItem onClick={handleClose} disableRipple>
  //         <Edit />
  //         {item.title}
  //       </MenuItem>
  //     ))}
  //   </>
  // );
  const currentPage = useLocation();
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        EXPERTISE
      </Typography>
      <Divider />
      <List>
        {headerPages.map((item, i) => (
          <ListItem
            style={{
              visibility: i === 6 && !isAdmin ? "hidden" : "visible",
              backgroundColor:
                currentPage.pathname === item.path && i != 1
                  ? "#796328"
                  : "transparent",
              color:
                currentPage.pathname === item.path && i != 1
                  ? "white"
                  : undefined,
            }}
            key={item.id}
            disablePadding
          >
            <ListItemButton
              href={i != 2 && i != 3 ? item.path : ""}
              onClick={
                i === 0
                  ? undefined
                  : i === 1
                  ? switchType
                  : i === 2
                  ? isAdmin
                    ? () => handleNavigate("/admin/pricing-requests")
                    : () => handleClickOpen(setOpenPricingDialog)
                  : i === 3
                  ? isAdmin
                    ? () => handleNavigate("/admin/claims")
                    : () => handleClickOpen(setOpenClaimsDialog)
                  : undefined
              }
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.value} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        zIndex: 500,
        position: "sticky",
        top: 0,
        // p: {
        // xs: "0",
        // sm: "0",
        // md: "0",
        // lg: "0 5rem 0 5rem",
        // xl: "0 5rem 0 5rem",
        // },
      }}
    >
      <AppBar component="nav" position="sticky" color="inherit" elevation={0}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              onClick={() => navigate("/")}
              sx={{
                width: {
                  xs: "15%",
                  sm: "15%",
                  md: "15%",
                  lg: "8%",
                  xl: "8%",
                },
                m: "1rem 0 0.3rem 0",
                cursor: "pointer",
              }}
            >
              <img src={ExpertiseLogo} alt="Expertise Logo" />
            </Box>
            <div style={{ width: "3%" }} />
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
                width: "85%",
                justifyContent: { lg: "space-between" },
              }}
            >
              {headerPages.map((header, i) =>
                i === 0 || i === 1 ? (
                  i === 0 ? (
                    <>
                      <Button
                        id="customized-button"
                        // aria-controls={open ? "customized-menu" : undefined}
                        // aria-haspopup="true"
                        // aria-expanded={open ? "true" : undefined}
                        variant="outlined"
                        // disableElevation
                        href={header.path}
                        sx={{ fontSize: "14px", mx: "0.5rem" }}
                        // onClick={handleClick}
                        // startIcon={<KeyboardArrowDown />}
                      >
                        {t(`${type.toLowerCase()}`)}
                      </Button>
                      {/* <Divider /> */}
                      {/* <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {type === TYPE.INDIVIDUALS
                          ? MenuItems(individualsMenu)
                          : MenuItems(corporatesMenu)}
                      </StyledMenu> */}
                    </>
                  ) : (
                    <>
                      <NavButton
                        key={header.id}
                        name={header.value}
                        path={header.path}
                        pathList={header.availablePaths}
                        onButtonClick={switchType}
                      />
                    </>
                  )
                ) : (
                  <NavButton
                    key={header.id}
                    name={header.value}
                    path={i != 2 && i != 3 ? header.path : undefined}
                    pathList={header.availablePaths}
                    visiblity={i === 6 && !isAdmin ? "hidden" : "visible"}
                    onButtonClick={
                      i === 2
                        ? isAdmin
                          ? () => handleNavigate("/admin/pricing-requests")
                          : () => handleClickOpen(setOpenPricingDialog)
                        : i === 3
                        ? isAdmin
                          ? () => handleNavigate("/admin/claims")
                          : () => handleClickOpen(setOpenClaimsDialog)
                        : undefined
                    }
                  />
                )
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LanguageSwitcher />
              {isAdmin && (
                <IconButton
                  href="/admin/login"
                  color="inherit"
                  edge="start"
                  size="large"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                  }}
                >
                  <LogoutRounded
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "24px",
                        md: "24px",
                        lg: "24px",
                      },
                    }}
                  />
                </IconButton>
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                size="large"
                onClick={handleDrawerToggle}
                sx={{ display: { lg: "none" } }}
              >
                <Menu
                  sx={{
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "24px",
                    },
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor={isEn ? "right" : "left"}
          SlideProps={{ direction: isEn ? "left" : "right" }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <PricingDialog
        open={openPricingDialog}
        handleClose={handleClosePricingDialog}
      />
      <ClaimsDialog
        open={openClaimsDialog}
        handleClose={handleCloseClaimsDialog}
      />
    </Box>
  );
};

export default NavigationBar;
