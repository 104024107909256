import { Box } from "@mui/material";
import SpiningLogo from "./spining_logo";

function Loading(props: { height?: string }) {
  return (
    <Box
      sx={{
        height: props.height ?? "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "100px", height: "100px" }}>
        <SpiningLogo />
      </Box>
    </Box>
  );
}

export default Loading;
