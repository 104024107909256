import { Add, Edit, Delete, Cancel } from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { t } from "i18next";
import { useState, useContext, useEffect, FormEventHandler } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import MyMap from "../../components/map";
import SpiningLogo from "../../components/spining_logo";
import { TextInput } from "../../components/text_input";
import { BranchType, MODE, BusinessHours, Days, mapsAPIKey } from "../../const";
import LocaleContext from "../../LocaleContext";
import {
  postRequest,
  putRequest,
  deleteRequest,
  getRequest,
} from "../../network/api-client";
import { parentBoxStyle, branchPropsSX, branchPropsItemSX } from "../../styles";

const AddOrEditBranch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { branchId } = useParams();
  const [branch, setBranch] = useState<BranchType>();
  const [mode, setMode] = useState<MODE>(branchId ? MODE.EDIT : MODE.ADD);
  const { register, handleSubmit, reset } = useForm();
  const {
    register: customerServicesPhonesRegister,
    handleSubmit: customerServicesPhonesHandleSubmit,
    reset: customerServicesPhonesReset,
  } = useForm();
  const {
    register: whatsappRegister,
    handleSubmit: whatsappHandleSubmit,
    reset: whatsappReset,
  } = useForm();
  const {
    register: faxRegister,
    handleSubmit: faxHandleSubmit,
    reset: faxReset,
  } = useForm();
  const {
    register: businessHoursRegister,
    handleSubmit: businessHoursHandleSubmit,
    reset: businessHoursReset,
  } = useForm();
  const [isSubmittingBranchLoading, setIsSubmittingBranchLoading] =
    useState(false);

  const { type } = useContext(LocaleContext);
  const [customerServicesPhones, setCustomerServicesPhones] = useState<any[]>(
    []
  );
  const [customerServicesPhonesFormMode, setCustomerServicesPhonesFormMode] =
    useState<MODE>(MODE.SHOW);
  const [editingCustomerServicePhoneId, setEditingCustomerServicePhoneId] =
    useState<string>();
  const [
    isCustomerServicePhoneSubmitting,
    setIsCustomerServicePhoneSubmitting,
  ] = useState(false);
  const [whatsapp, setWhatsapp] = useState<any[]>([]);
  const [whatsappFormMode, setWhatsappFormMode] = useState<MODE>(MODE.SHOW);
  const [editingWhatsappId, setEditingWhatsappId] = useState<string>();
  const [isWhatsappSubmitting, setIsWhatsappSubmitting] = useState(false);
  const [fax, setFax] = useState<any[]>([]);
  const [faxFormMode, setFaxFormMode] = useState<MODE>(MODE.SHOW);
  const [editingFaxId, setEditingFaxId] = useState<string>();
  const [isFaxSubmitting, setIsFaxSubmitting] = useState(false);

  const [businessHours, setBusinessHours] = useState<BusinessHours[]>([]);
  const [businessHoursFormMode, setBusinessHoursFormMode] = useState<MODE>(
    MODE.SHOW
  );
  const [editingBusinessHoursId, setEditingBusinessHoursId] =
    useState<string>();
  const [isBusinessHoursSubmitting, setIsBusinessHoursSubmitting] =
    useState(false);
  const [detectedLatlng, setDetectedLatLng] = useState<google.maps.LatLng>();

  //==============================================================
  const [openPickLocationDialog, setOpenPickLocationDialog] = useState(false);

  const handleClickOpenPickLocationDialog = () => {
    setOpenPickLocationDialog(true);
  };

  const handleClosePickLocationDialog = () => {
    setOpenPickLocationDialog(false);
  };
  //==============================================================

  const onSubmit = async (data: any) => {
    let response;
    let branch = {};
    if (mode === MODE.ADD) {
      branch = {
        longitude: detectedLatlng?.toJSON()?.lng?.toString(),
        latitude: detectedLatlng?.toJSON()?.lat?.toString(),
        address: data.address,
        addressAr: data.addressAr,
        email: data.email,
        customerServicesPhones: customerServicesPhones.map(
          (phone) => phone.num
        ),
        whatsApp: whatsapp.map((whatsapp) => whatsapp.num),
        fax: fax.map((fax) => fax.num),
        bussiness: businessHours,
      };
    } else if (mode === MODE.EDIT) {
      branch = {
        longitude: detectedLatlng?.toJSON()?.lng?.toString(),
        latitude: detectedLatlng?.toJSON()?.lat?.toString(),
        address: data.address,
        addressAr: data.addressAr,
        email: data.email,
      };
    }

    Swal.fire({
      icon: "info",
      title: t("are-you-sure"),
      text:
        (mode === MODE.ADD ? t("add-branch") : t("edit-branch")) + data.address,
      confirmButtonText: t("ok"),
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsSubmittingBranchLoading(true);
        if (mode === MODE.ADD) {
          response = await postRequest("branches/", branch).finally(() =>
            setIsSubmittingBranchLoading(false)
          );
          if (response) {
            navigate("/branches");
          }
        } else if (mode === MODE.EDIT) {
          response = await putRequest("branches/" + branchId, branch).finally(
            () => setIsSubmittingBranchLoading(false)
          );
          if (response) {
            navigate("/branches");
          }
        }
      }
    });
  };

  const onCustomerServicesPhonesFormSubmit = async (data: any) => {
    setIsCustomerServicePhoneSubmitting(true);
    console.log("cspf: " + data.phone);
    if (mode === MODE.ADD) {
      setCustomerServicesPhones((prev) => [...prev, { num: data.phone }]);
      customerServicesPhonesReset({ phone: "" });
      setIsCustomerServicePhoneSubmitting(false);
      setCustomerServicesPhonesFormMode(MODE.SHOW);
    } else if (mode === MODE.EDIT) {
      let response;
      if (customerServicesPhonesFormMode === MODE.ADD) {
        response = await postRequest("branches/phones/" + branchId, {
          num: data.phone,
        }).finally(() => setIsCustomerServicePhoneSubmitting(false));
      } else if (customerServicesPhonesFormMode === MODE.EDIT) {
        response = await putRequest(
          "branches/phones/" + editingCustomerServicePhoneId,
          { num: data.phone }
        ).finally(() => setIsCustomerServicePhoneSubmitting(false));
      }
      if (response) {
        fetchBranch();
        setCustomerServicesPhonesFormMode(MODE.SHOW);
      }
    }
  };

  const onWhatsappFormSubmit = async (data: any) => {
    setIsWhatsappSubmitting(true);
    if (mode === MODE.ADD) {
      setWhatsapp((prev) => [...prev, { num: data.whatsapp }]);
      whatsappReset({ whatsapp: "" });
      setIsWhatsappSubmitting(false);
      setWhatsappFormMode(MODE.SHOW);
    } else if (mode === MODE.EDIT) {
      let response;
      if (whatsappFormMode === MODE.ADD) {
        response = await postRequest("branches/whats-app/" + branchId, {
          num: data.whatsapp,
        }).finally(() => setIsWhatsappSubmitting(false));
      } else if (whatsappFormMode === MODE.EDIT) {
        response = await putRequest("branches/whats-app/" + editingWhatsappId, {
          num: data.whatsapp,
        }).finally(() => setIsWhatsappSubmitting(false));
      }
      if (response) {
        fetchBranch();
        setWhatsappFormMode(MODE.SHOW);
      }
    }
  };

  const onFaxFormSubmit = async (data: any) => {
    setIsFaxSubmitting(true);
    if (mode === MODE.ADD) {
      setFax((prev) => [...prev, { num: data.fax }]);
      faxReset({ fax: "" });
      setIsFaxSubmitting(false);
      setFaxFormMode(MODE.SHOW);
    } else if (mode === MODE.EDIT) {
      let response;
      if (faxFormMode === MODE.ADD) {
        response = await postRequest("branches/fax/" + branchId, {
          num: data.fax,
        }).finally(() => setIsFaxSubmitting(false));
      } else if (faxFormMode === MODE.EDIT) {
        response = await putRequest("branches/fax/" + editingFaxId, {
          num: data.fax,
        }).finally(() => setIsFaxSubmitting(false));
      }
      if (response) {
        fetchBranch();
        setFaxFormMode(MODE.SHOW);
      }
    }
  };

  const onBusinessHoursFormSubmit = async (data: any) => {
    setIsBusinessHoursSubmitting(true);
    if (mode === MODE.ADD) {
      setBusinessHours((prev) => [
        ...prev,
        {
          bussinessDayFrom: data.bussinessDayFrom,
          bussinessHoursFrom: data.bussinessHoursFrom,
          bussinessDayTo: data.bussinessDayTo,
          bussinessHoursTo: data.bussinessHoursTo,
        },
      ]);
      businessHoursReset({
        bussinessDayFrom: "",
        bussinessHoursFrom: "",
        bussinessDayTo: "",
        bussinessHoursTo: "",
      });
      setIsBusinessHoursSubmitting(false);
      setBusinessHoursFormMode(MODE.SHOW);
    } else if (mode === MODE.EDIT) {
      let response;
      if (businessHoursFormMode === MODE.ADD) {
        response = await postRequest("branches/branchBussiness", {
          branchId: branchId,
          bussinessHoursFrom: data.bussinessHoursFrom,
          bussinessHoursTo: data.bussinessHoursTo,
          bussinessDayFrom: data.bussinessDayFrom,
          bussinessDayTo: data.bussinessDayTo,
        }).finally(() => setIsBusinessHoursSubmitting(false));
      } else if (businessHoursFormMode === MODE.EDIT) {
        response = await putRequest(
          "branches/branchBussiness/" + editingBusinessHoursId,
          {
            bussinessHoursFrom: data.bussinessHoursFrom,
            bussinessHoursTo: data.bussinessHoursTo,
            bussinessDayFrom: data.bussinessDayFrom,
            bussinessDayTo: data.bussinessDayTo,
          }
        ).finally(() => setIsBusinessHoursSubmitting(false));
      }
      if (response) {
        fetchBranch();
        setBusinessHoursFormMode(MODE.SHOW);
      }
    }
  };

  const handleDeleteItem = async (path: string) => {
    const res = await deleteRequest(path);
    if (res) {
      fetchBranch();
    }
  };

  const resetBranch = () => {
    reset({
      address: branch?.address,
      addressAr: branch?.addressAr,
      email: branch?.email,
    });
  };

  const fetchBranch = async () => {
    await getRequest("branches/" + branchId).then((res) => {
      setBranch(res?.branche);
      setBusinessHours(res?.branche?.bussiness);
      setWhatsapp(res?.branche?.whatsApp);
      setCustomerServicesPhones(res?.branche?.customerServicesPhones);
      setFax(res?.branche.fax);
      reset({
        address: res?.branche?.address,
        addressAr: res?.branche?.addressAr,
        email: res?.branche?.email,
      });
    });
  };

  useEffect(() => {
    mode === MODE.EDIT && fetchBranch();
  }, []);

  return (
    <Box sx={{ ...parentBoxStyle, height: "auto" }}>
      {/* main  add form */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Typography gutterBottom variant="h5" component="div">
          {mode === MODE.ADD ? t("add-branch") : t("edit-branch")}
        </Typography>
        <br />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            width: "100%",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "60%" },
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: { md: "48%" },
              }}
            >
              <TextInput
                title={t("address-in-en")}
                errorMessage={"Enter English address"}
                value={branch?.address}
                required={true}
                registeration={{ ...register("address") }}
              />
            </Box>
            <Box
              sx={{
                width: { md: "48%" },
              }}
            >
              <TextInput
                title={t("address-in-ar")}
                errorMessage={"Enter Arabic address"}
                value={branch?.addressAr}
                required={true}
                registeration={{ ...register("addressAr") }}
              />
            </Box>
          </Box>

          <Button
            variant="outlined"
            sx={{
              height: "50px",
              width: { xs: "100%", sm: "100%", md: "30%" },
              mb: "1.5rem",
            }}
            onClick={() => {
              setOpenPickLocationDialog(true);
              console.log("pick location clicked!");
            }}
          >
            {t("pick-location")}
          </Button>
        </Box>
        <TextInput
          title={t("email")}
          errorMessage={"Enter branch email"}
          required={true}
          value={branch?.email}
          type="email"
          registeration={{ ...register("email") }}
        />

        <br />
        <Button
          fullWidth
          type="submit"
          disabled={isSubmittingBranchLoading}
          variant="contained"
          size="large"
        >
          {isSubmittingBranchLoading ? (
            <SpiningLogo height="20px" />
          ) : (
            t("submit")
          )}
        </Button>
      </form>
      <br />
      {/* branch props -phones- */}
      <Box sx={branchPropsSX}>
        <Box sx={branchPropsItemSX}>
          <BranchPropHeader
            title={t("customer-service-phone")}
            mode={customerServicesPhonesFormMode}
            onAddClick={() => {
              setCustomerServicesPhonesFormMode(MODE.ADD);
            }}
            onCancelClick={() => {
              setCustomerServicesPhonesFormMode(MODE.SHOW);
              customerServicesPhonesReset();
            }}
          />
          {customerServicesPhones?.length > 0 &&
            customerServicesPhones?.map((phone, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{phone.num}</Typography>

                <Box>
                  {mode === MODE.EDIT && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setEditingCustomerServicePhoneId(phone.id);
                        setCustomerServicesPhonesFormMode(MODE.EDIT);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (mode === MODE.ADD) {
                        let temp = customerServicesPhones;
                        temp = temp.filter((tempItem) => {
                          if (phone != tempItem) {
                            return tempItem;
                          }
                        });
                        setCustomerServicesPhones(temp);
                      } else if (mode === MODE.EDIT) {
                        handleDeleteItem("branches/phones/" + phone.id);
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          {customerServicesPhonesFormMode != MODE.SHOW && (
            <OneForm
              onSubmit={customerServicesPhonesHandleSubmit(
                onCustomerServicesPhonesFormSubmit
              )}
              submittingMode={isCustomerServicePhoneSubmitting}
              oneFieldValue={
                customerServicesPhonesFormMode === MODE.EDIT
                  ? branch?.customerServicesPhones.find(
                      (phone) =>
                        phone.id === Number(editingCustomerServicePhoneId)
                    )?.num
                  : undefined
              }
              // title={t("customer-service-phone")}
              errorMessage={"Enter customer service phone"}
              registration={{
                ...customerServicesPhonesRegister("phone"),
              }}
            />
          )}
        </Box>
        <Box sx={branchPropsItemSX}>
          <BranchPropHeader
            title={t("whatsapp")}
            mode={whatsappFormMode}
            onAddClick={() => {
              setWhatsappFormMode(MODE.ADD);
            }}
            onCancelClick={() => {
              setWhatsappFormMode(MODE.SHOW);
            }}
          />
          {whatsapp?.length > 0 &&
            whatsapp?.map((phone, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{phone.num}</Typography>
                <Box>
                  {mode === MODE.EDIT && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setEditingWhatsappId(phone.id);
                        setWhatsappFormMode(MODE.EDIT);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (mode === MODE.ADD) {
                        let temp = whatsapp;
                        temp = temp.filter((tempItem) => {
                          if (phone != tempItem) {
                            return tempItem;
                          }
                        });
                        setWhatsapp(temp);
                      } else if (mode === MODE.EDIT) {
                        handleDeleteItem("branches/whats-app/" + phone.id);
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          {whatsappFormMode != MODE.SHOW && (
            <OneForm
              onSubmit={whatsappHandleSubmit(onWhatsappFormSubmit)}
              submittingMode={isWhatsappSubmitting}
              oneFieldValue={
                whatsappFormMode === MODE.EDIT
                  ? branch?.whatsApp.find(
                      (phone) => phone.id === Number(editingWhatsappId)
                    )?.num
                  : undefined
              }
              errorMessage={"Enter whatsapp number"}
              registration={{
                ...whatsappRegister("whatsapp"),
              }}
            />
          )}
        </Box>
        <Box sx={branchPropsItemSX}>
          <BranchPropHeader
            title={t("fax")}
            mode={faxFormMode}
            onAddClick={() => {
              setFaxFormMode(MODE.ADD);
            }}
            onCancelClick={() => {
              setFaxFormMode(MODE.SHOW);
            }}
          />
          {fax?.length > 0 &&
            fax?.map((phone, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{phone.num}</Typography>
                <Box>
                  {mode === MODE.EDIT && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setEditingFaxId(phone.id);
                        setFaxFormMode(MODE.EDIT);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (mode === MODE.ADD) {
                        let temp = fax;
                        temp = temp.filter((tempItem) => {
                          if (phone != tempItem) {
                            return tempItem;
                          }
                        });
                        setFax(temp);
                      } else if (mode === MODE.EDIT) {
                        handleDeleteItem("branches/fax/" + phone.id);
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          {faxFormMode != MODE.SHOW && (
            <OneForm
              onSubmit={faxHandleSubmit(onFaxFormSubmit)}
              submittingMode={isFaxSubmitting}
              oneFieldValue={
                faxFormMode === MODE.EDIT
                  ? branch?.fax.find(
                      (phone) => phone.id === Number(editingFaxId)
                    )?.num
                  : undefined
              }
              errorMessage={"Enter fax number"}
              registration={{
                ...faxRegister("fax"),
              }}
            />
          )}
        </Box>
        <Box sx={branchPropsItemSX}>
          <BranchPropHeader
            title={t("business-hours")}
            mode={businessHoursFormMode}
            onAddClick={() => {
              setBusinessHoursFormMode(MODE.ADD);
            }}
            onCancelClick={() => {
              setBusinessHoursFormMode(MODE.SHOW);
            }}
          />
          {businessHours?.length > 0 &&
            businessHours?.map((business, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography>
                    {"From day: " +
                      business.bussinessDayFrom +
                      ", To day: " +
                      business.bussinessDayTo}
                  </Typography>
                  <Typography>
                    {"From hour: " +
                      business.bussinessHoursFrom +
                      ", To hour: " +
                      business.bussinessHoursTo}
                  </Typography>
                </Box>
                <Box>
                  {mode === MODE.EDIT && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setEditingBusinessHoursId(business.id);
                        businessHoursReset({
                          bussinessDayFrom: business.bussinessDayFrom,
                          bussinessDayTo: business.bussinessDayTo,
                          bussinessHoursFrom: business.bussinessHoursFrom,
                          bussinessHoursTo: business.bussinessHoursTo,
                        });
                        setBusinessHoursFormMode(MODE.EDIT);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (mode === MODE.ADD) {
                        let temp = businessHours;
                        temp = temp.filter((tempItem) => {
                          if (business != tempItem) {
                            return tempItem;
                          }
                        });
                        setBusinessHours(temp);
                      } else if (mode === MODE.EDIT) {
                        handleDeleteItem(
                          "branches/branchBussiness/" + business.id
                        );
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      <br />
      {/* business hours form */}
      {businessHoursFormMode != MODE.SHOW && (
        <form
          style={{ width: "100%" }}
          onSubmit={businessHoursHandleSubmit(onBusinessHoursFormSubmit)}
        >
          <Typography variant="h6">{t("business-hours")}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              width: "100%",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
              <TextInput
                title={t("days-from")}
                errorMessage={"Enter days from"}
                required={true}
                value={
                  businessHoursFormMode === MODE.EDIT
                    ? branch?.bussiness.find(
                        (business) =>
                          business.id === Number(editingBusinessHoursId)
                      )?.bussinessDayFrom
                    : undefined
                }
                list={Days}
                isAutoComplete={false}
                registeration={{ ...businessHoursRegister("bussinessDayFrom") }}
              />
            </Box>
            <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
              <TextInput
                title={t("hours-from")}
                errorMessage={"Enter hours from"}
                required={true}
                value={
                  businessHoursFormMode === MODE.EDIT
                    ? branch?.bussiness.find(
                        (business) =>
                          business.id === Number(editingBusinessHoursId)
                      )?.bussinessHoursFrom
                    : undefined
                }
                registeration={{
                  ...businessHoursRegister("bussinessHoursFrom"),
                }}
              />
            </Box>
            <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
              <TextInput
                title={t("days-to")}
                errorMessage={"Enter days to"}
                required={true}
                value={
                  businessHoursFormMode === MODE.EDIT
                    ? branch?.bussiness.find(
                        (business) =>
                          business.id === Number(editingBusinessHoursId)
                      )?.bussinessDayTo
                    : undefined
                }
                list={Days}
                isAutoComplete={false}
                registeration={{ ...businessHoursRegister("bussinessDayTo") }}
              />
            </Box>
            <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
              <TextInput
                title={t("hours-to")}
                errorMessage={"Enter hours ro"}
                required={true}
                value={
                  businessHoursFormMode === MODE.EDIT
                    ? branch?.bussiness.find(
                        (business) =>
                          business.id === Number(editingBusinessHoursId)
                      )?.bussinessHoursTo
                    : undefined
                }
                registeration={{ ...businessHoursRegister("bussinessHoursTo") }}
              />
            </Box>
            <Button
              variant="contained"
              type="submit"
              disabled={isBusinessHoursSubmitting}
              sx={{ height: "50px", width: "30%", mb: "1.5rem" }}
            >
              {isBusinessHoursSubmitting ? (
                <SpiningLogo height="20px" />
              ) : (
                t("add")
              )}
            </Button>
          </Box>
        </form>
      )}
      {/* pick location dialog */}
      <Dialog
        open={openPickLocationDialog}
        fullWidth
        // onClose={handleClosePickLocationDialog}
        aria-labelledby="pick-branch-location-title"
        aria-describedby="pick-branch-location-description"
      >
        <DialogTitle id="pick-branch-location-title">
          {"Pick branch location"}
        </DialogTitle>
        <DialogContent sx={{ height: "50vh" }}>
          <MyMap
            detectedLatLng={detectedLatlng}
            setDetectedLatLng={setDetectedLatLng}
            markerLat={
              mode === MODE.EDIT ? Number(branch?.latitude!)! : undefined
            }
            markerLng={
              mode === MODE.EDIT ? Number(branch?.longitude!)! : undefined
            }
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClosePickLocationDialog}>
            {t("done")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const OneForm = (props: {
  onSubmit: FormEventHandler<HTMLFormElement>;
  submittingMode: boolean;
  title?: string;
  oneFieldValue?: string;
  errorMessage: string;
  registration: any;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%" }}>
      <form style={{ width: "100%" }} onSubmit={props.onSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "60%" }}>
            <TextInput
              title={props.title}
              errorMessage={props.errorMessage}
              value={props.oneFieldValue}
              required={true}
              type="number"
              registeration={props.registration}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={props.submittingMode}
            sx={{ height: "50px", width: "30%", mb: "1.5rem" }}
          >
            {props.submittingMode ? <SpiningLogo height="20px" /> : t("add")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

const BusinessHoursForm = (props: {
  onSubmit: FormEventHandler<HTMLFormElement>;
  title: string;
  mode: MODE;
  branch: any;
  businessRegister: any;
  editingId?: string;
}) => {
  return (
    <form style={{ width: "100%" }} onSubmit={props.onSubmit}>
      <Typography variant="h6">{props.title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          width: "100%",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
          <TextInput
            title={t("days-from")}
            errorMessage={"Enter days from"}
            // required={true}
            value={
              props.mode === MODE.EDIT
                ? props.branch?.bussiness.find(
                    (business: any) => business.id === Number(props.editingId)
                  )?.bussinessDayFrom
                : undefined
            }
            list={Days}
            isAutoComplete={false}
            registeration={{ ...props.businessRegister("bussinessDayFrom") }}
          />
        </Box>
        <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
          <TextInput
            title={t("hours-from")}
            errorMessage={"Enter hours from"}
            // required={true}
            value={
              props.mode === MODE.EDIT
                ? props.branch?.bussiness.find(
                    (business: any) => business.id === Number(props.editingId)
                  )?.bussinessHoursFrom
                : undefined
            }
            registeration={{
              ...props.businessRegister("bussinessHoursFrom"),
            }}
          />
        </Box>
        <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
          <TextInput
            title={t("days-to")}
            errorMessage={"Enter days to"}
            // required={true}
            value={
              props.mode === MODE.EDIT
                ? props.branch?.bussiness.find(
                    (business: any) => business.id === Number(props.editingId)
                  )?.bussinessDayTo
                : undefined
            }
            list={Days}
            isAutoComplete={false}
            registeration={{ ...props.businessRegister("bussinessDayTo") }}
          />
        </Box>
        <Box sx={{ width: "100%", px: { xs: "0", sm: "0", md: "0.5rem" } }}>
          <TextInput
            title={t("hours-to")}
            errorMessage={"Enter hours ro"}
            // required={true}
            value={
              props.mode === MODE.EDIT
                ? props.branch?.bussiness.find(
                    (business: any) => business.id === Number(props.editingId)
                  )?.bussinessHoursTo
                : undefined
            }
            registeration={{ ...props.businessRegister("bussinessHoursTo") }}
          />
        </Box>
        <Button
          variant="contained"
          type="submit"
          sx={{ height: "50px", width: "30%", mb: "1.5rem" }}
        >
          add
        </Button>
      </Box>
    </form>
  );
};

const BranchPropHeader = (props: {
  title: string;
  mode: MODE;
  onAddClick: () => void;
  onCancelClick: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h6">{props.title}</Typography>
      {props.mode === MODE.SHOW && (
        <IconButton color="primary" onClick={props.onAddClick}>
          <Add />
        </IconButton>
      )}
      {props.mode != MODE.SHOW && (
        <IconButton color="primary" onClick={props.onCancelClick}>
          <Cancel />
        </IconButton>
      )}
    </Box>
  );
};

export default AddOrEditBranch;
