// import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { ArDay, EnDay } from "../const";
import i18n from "../i18n";

export const getLanguageBasedDay = (day: string) => {
  const isEn = i18n.language === "en-US";
  switch (day) {
    case EnDay.saturday:
      return isEn ? EnDay.saturday : ArDay.saturday;
      break;
    case EnDay.sunday:
      return isEn ? EnDay.sunday : ArDay.sunday;
      break;
    case EnDay.monday:
      return isEn ? EnDay.monday : ArDay.monday;
      break;
    case EnDay.tuesday:
      return isEn ? EnDay.tuesday : ArDay.tuesday;
      break;
    case EnDay.wednesday:
      return isEn ? EnDay.wednesday : ArDay.wednesday;
      break;
    case EnDay.thursday:
      return isEn ? EnDay.thursday : ArDay.thursday;
      break;
    case EnDay.friday:
      return isEn ? EnDay.friday : ArDay.friday;
      break;
  }
};
