import {
  Add,
  Delete,
  Edit,
  FaxOutlined,
  LocationCity,
  LocationOn,
  LocationOnOutlined,
  MailOutline,
  MailOutlineOutlined,
  PhoneOutlined,
  WhatsApp,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Divider,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Address } from "../components/address";
import Loading from "../components/loading";
import LoadingBox from "../components/loading_box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/mui_customized_accordion";
import SectionHeader from "../components/section_header";
import SpiningLogo from "../components/spining_logo";
import { BranchType, isAdmin, TYPE } from "../const";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import { deleteRequest, getRequest } from "../network/api-client";
import {
  flexableParentBoxSX,
  headerActionButtonStyle,
  IconButtonSX,
} from "../styles";

export default function Branches() {
  const type = sessionStorage.getItem("type");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [branches, setBranches] = useState<BranchType[]>([]);
  const [isBranchesLoading, setIsBranchesLoading] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleDeleteBranch = async (branchId: number) => {
    Swal.fire({
      icon: "warning",
      title: t("are-you-sure"),
      text: t("delete-branch"),
      confirmButtonText: t("delete"),
      showCancelButton: true,
      // confirmButtonColor: primaryColor,
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteRequest("branches/" + branchId);
        if (res) {
          fetchBranches();
        }
      }
    });
  };

  const handleEditBranch = (branchId: number) =>
    navigate("/admin/edit-branch/" + branchId);

  const fetchBranches = async () => {
    setIsBranchesLoading(true);
    const response = await getRequest("branches/");
    setBranches(response?.branches);
    setIsBranchesLoading(false);
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <Box sx={flexableParentBoxSX}>
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "65%" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SectionHeader
          title={t("branches")}
          adminActions={[
            <IconButton
              style={headerActionButtonStyle}
              onClick={() => navigate("/admin/add-branch")}
            >
              <Add />
            </IconButton>,
          ]}
        />
        {isBranchesLoading ? (
          <Loading height="40vh" />
        ) : (
          branches?.map((branch: BranchType, i: number) => (
            <Accordion
              expanded={expanded === i.toString()}
              onChange={handleAccordionChange(i.toString())}
            >
              <AccordionSummary aria-controls={i.toString()} id={i.toString()}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      p: i18n.language === "ar" ? "0 0.5rem 0 0" : undefined,
                      color: "white",
                    }}
                  >
                    {i18n.language === "ar" ? branch.addressAr : branch.address}
                  </Typography>
                  {isAdmin && (
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        size="small"
                        style={IconButtonSX}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleEditBranch(branch.id);
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        style={IconButtonSX}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleDeleteBranch(branch.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {Number(branch?.latitude) !== ("NaN" && undefined) &&
                  Number(branch?.longitude) !== ("NaN" && undefined) && (
                    <Address branch={branch} />
                  )}
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "30%" },
        }}
      >
        <SectionHeader
          title={t("head-office")}
          adminActions={[
            <IconButton style={headerActionButtonStyle} onClick={() => {}}>
              <Edit />
            </IconButton>,
          ]}
        />
        {isBranchesLoading ? (
          <LoadingBox />
        ) : (
          branches[0]?.longitude !== ("NaN" && undefined) &&
          branches[0]?.latitude !== ("NaN" && undefined) && (
            <Address branch={branches[0]} />
          )
        )}
      </Box>
    </Box>
  );
}
