import { Edit } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isAdmin } from "../const";
import i18n from "../i18n";

const SectionHeader = (props: {
  title?: string;
  adminActions?: any[];
  dividerHeight?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          textAlign={"start"}
          sx={
            {
              // typography: { xs: "h6", sm: "h6", md: "h6", lg: "h5", xl: "h5" },
            }
          }
        >
          {props.title}
        </Typography>
        {isAdmin && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {props.adminActions?.map((action) => action)}
          </Box>
          // <button
          //   style={{
          //     margin:
          //       i18n.language === "en-US"
          //         ? "0.5rem 0.5rem 0.5rem 0"
          //         : "0.5rem 0 0.5rem 0.5rem",
          //     color: "#796328",
          //     backgroundColor: "transparent",
          //     border: "2px solid #796328",
          //     borderRadius: "100%",
          //     cursor: "pointer",
          //     display: "flex",
          //     alignItems: "center",
          //     height: "3rem",
          //   }}
          //   onClick={() => {}}
          // >
          //   <Edit />
          // </button>
        )}
      </Box>
      <Divider
        orientation="horizontal"
        sx={{
          width: "100%",
          height: props.dividerHeight ?? "0.1rem",
          backgroundColor: "#FFCB8E",
          marginBottom: "1rem",
        }}
      />
    </Box>
  );
};

export default SectionHeader;
