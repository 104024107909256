import {
  Add,
  Delete,
  Edit,
  EditOutlined,
  EditRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingBox from "../../components/loading_box";
import SectionHeader from "../../components/section_header";
import SpiningLogo from "../../components/spining_logo";
import { baseColumnProperities, filterTranslations } from "../../const";
import i18n from "../../i18n";
import LocaleContext from "../../LocaleContext";
import {
  deleteRequest,
  getRequest,
  handleApiClientError,
} from "../../network/api-client";
import {
  headerActionButtonStyle,
  parentBoxStyle,
  userListTileStyle,
} from "../../styles";

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const handleAddNewUser = () => {
    navigate("/admin/add-user");
  };

  const handleDeleteUser = async (userId: string) => {
    Swal.fire({
      icon: "warning",
      title: t("are-you-sure"),
      text: t("delete-user"),
      confirmButtonText: t("delete"),
      showCancelButton: true,
      // confirmButtonColor: primaryColor,
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsDeletingUser(true);
        await deleteRequest("users/" + userId).then(
          (res) => {
            setIsDeletingUser(false);
            if (res) {
              Swal.fire({
                title: t("deleted"),
                text: res.detail as string,
                icon: "success",
                background: "#fff",
                color: "#000",
                confirmButtonText: t("ok"),
                // confirmButtonColor: primaryColor,
              }).then((result) => {
                if (result.isConfirmed) {
                  fetchUsers();
                }
              });
            }
          },
          (error) => {
            setIsDeletingUser(false);
            handleApiClientError(error);
          }
        );
      }
    });
  };

  const handleEditUser = (userId: string) => {
    navigate({ pathname: "/admin/edit-user/" + userId });
  };

  const fetchUsers = async () => {
    setIsUsersLoading(true);
    await getRequest("users/").then(
      (res) => {
        setUsers(res.users);
        setIsUsersLoading(false);
      },
      (error) => {
        setIsUsersLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box sx={parentBoxStyle}>
      <SectionHeader
        title={t("users")}
        adminActions={[
          <IconButton
            style={headerActionButtonStyle}
            onClick={handleAddNewUser}
          >
            <Add />
          </IconButton>,
        ]}
      />
      <Box sx={{ width: "100%", height: "70%" }}>
        {!isUsersLoading ? (
          <DataGrid
            rows={users}
            columns={[
              {
                ...baseColumnProperities,
                field: "username",
                headerName: t("username"),
              },
              {
                ...baseColumnProperities,
                field: "email",
                headerName: t("email"),
              },
              {
                field: "action",
                headerName: t("delete"),
                sortable: false,
                flex: 1,
                renderCell: (params: any) => (
                  <Button
                    // sx={{ color: "white" }}
                    variant={"contained"}
                    endIcon={<Delete />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteUser(params.row.id);
                    }}
                  >
                    {t("delete")}
                  </Button>
                ),
              },
            ]}
            sx={{ width: "100%" }}
            getRowId={(row: any) => row.id}
            onRowClick={(event) => {
              handleEditUser(event.row.id);
            }}
            loading={users.length < 1}
            localeText={i18n.language === "ar" ? filterTranslations : undefined}
          />
        ) : (
          <LoadingBox />
        )}
      </Box>
    </Box>
  );
};

export default Users;
