import {
  Autocomplete,
  Button,
  Container,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import i18n from "../i18n";

import { style } from "../styles";

/**
 * *example 
 *  <TextInput
            title="الوحدة"
            list={units}
            optionValue={course?.unit}
            required={true}
            registeration={{ ...register("unit") }}
          />
 */
type propsType = {
  title?: string;
  onTitleButtonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  titleButtonText?: string;
  // isDisablePadding?: boolean;
  list?: {}[];
  optionValue?: any;
  type?: string;
  // className?: string;
  titleClassName?: string;
  required?: boolean;
  valueIsCodeOrId?: boolean;
  registeration?: any;
  setValueCode?: any;
  setValueName?: any;
  maxLength?: number;
  setValueOnClick?: any;
  inputProps?: {};
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  // dir?: string;
  height?: string;
  multiLine?: boolean;
  rows?: number;
  disabled?: boolean;
  reset?: UseFormReset<FieldValues>;
  customStyle?: React.CSSProperties | undefined;
  onChangeCapture?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isAutoComplete?: boolean;
};

export const TextInput = (props: propsType) => {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [optionValue, setOptionValue] = useState({});

  useEffect(() => {
    const fetch = () => {
      setTextFieldValue(props.value ? props.value : "");
      setOptionValue(props.optionValue ? props.optionValue : {});
    };
    fetch();
  }, [props.value, props.optionValue]);

  const [focusCounter, setFocusCounter] = useState(0);

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      // className={props.className}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {props.title && (
          <Typography
            variant="h6"
            align="justify"
            noWrap
            className={props.titleClassName ?? "text-teal-600"}
          >
            {props.title}
          </Typography>
        )}
        {props.onTitleButtonClick && props.titleButtonText && (
          <Button
            variant="contained"
            style={{ padding: 0 }}
            onClick={props.onTitleButtonClick}
          >
            {props.titleButtonText}
          </Button>
        )}
      </div>
      {(props.isAutoComplete ?? true) && props.list ? (
        <Autocomplete
          disablePortal
          options={props.list}
          getOptionLabel={(option: any) => {
            props.reset && props.reset();
            return option.name || "";
          }}
          value={optionValue ?? null}
          style={props.customStyle ?? style}
          onChange={(event, value: any) => {
            setOptionValue(value);
            if (props.setValueCode) {
              props.setValueCode(value?.code ?? value?.id);
            }
            props.setValueName && props.setValueName(value?.name);
            props.setValueOnClick && props.setValueOnClick(value?.username);
            props.onChangeCapture && props.onChangeCapture(value);
          }}
          disabled={props.disabled ?? false}
          renderInput={(params) => (
            <TextField
              {...params}
              required={props.required ? props.required : false}
              {...(props.registeration ?? undefined)}
              dir={i18n.language === "en-US" ? "ltr" : "rtl"}
            />
          )}
        />
      ) : (
        <TextField
          maxLength={props.maxLength}
          onInput={(e: any) => {
            if (props.maxLength && props.type === "number") {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, props.maxLength);
            }
          }}
          InputProps={{
            inputProps: props.inputProps ?? { min: 0 },
            autoComplete: "disabled",
          }}
          id="outlined-select-user"
          select={props.list ? true : false}
          SelectProps={{
            MenuProps: {
              style: { width: 200, height: 400 },
              onLoad: (event) => {},
            },
            "aria-autocomplete": "list",
          }}
          style={{ ...style, height: props.height ?? "50px" }}
          multiline={props.multiLine ?? undefined}
          rows={props.rows ?? undefined}
          required={props.required ? props.required : false}
          {...props.registeration}
          dir={i18n.language === "en-US" ? "ltr" : "rtl"}
          placeholder={props.placeholder ? props.placeholder : ""}
          onFocus={() => setFocusCounter(1)}
          type={props.type ? props.type : "string"}
          value={textFieldValue}
          onChange={(event) => {
            setTextFieldValue(event.target.value);
            if (props.setValueCode) props.setValueCode(event.target.value);
          }}
          onChangeCapture={props.onChangeCapture}
          error={textFieldValue === "" && focusCounter === 1 && props.required}
          helperText={
            textFieldValue === "" && focusCounter === 1
              ? props.errorMessage
                ? props.errorMessage
                : props.required
                ? "برجاء إدخال هذا الحقل"
                : ""
              : ""
          }
          fullWidth
          disabled={props.disabled ? props.disabled : false}
        >
          {props.list
            ? props.list.map((item: any) => (
                <MenuItem
                  key={item.code}
                  onClick={(_) => {
                    if (props.setValueOnClick)
                      props.setValueOnClick(item.username);
                  }}
                  value={
                    props.valueIsCodeOrId
                      ? item.code
                      : item.name ?? item.username
                  }
                  style={{ justifyContent: "flex-end" }}
                >
                  <Typography align="right">
                    {item.name ?? item.value ?? item.username}
                  </Typography>
                </MenuItem>
              ))
            : null}
        </TextField>
      )}
    </div>
  );
};

// TODO handle dynamic properity name
