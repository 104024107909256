import { Wrapper } from "@googlemaps/react-wrapper";
import {
  FaxOutlined,
  LocationOnOutlined,
  MailOutlineOutlined,
  PhoneOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BranchType, mapsAPIKey } from "../const";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import { getLanguageBasedDay } from "../utils/utils";
import Loading from "./loading";
import MyMap from "./map";
// import MapWrapper from "./map/map_wrapper";
import SpiningLogo from "./spining_logo";

export const AddressSection = (props: {
  icon: any;
  label: string;
  details?: { id: number; num?: string }[];
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        m: "0.5rem",
      }}
    >
      {props.details && props.details?.length > 0 && (
        <>
          <Box
            sx={{
              backgroundColor: "#FFCB8E",
              borderRadius: "100%",
              p: "0.3rem",
              m: "0 0.3rem 0 0.3rem",
              display: "flex",
              alignItems: "center",
              placeSelf: "center",
              color: "white",
            }}
          >
            {props.icon}
          </Box>
          <Typography variant="subtitle1">{props.label + ": "}</Typography>
          <Typography variant="subtitle2">
            {props?.details?.map((detail: any, i: number) =>
              props.details && i < props.details?.length
                ? detail.num + " , "
                : detail.num
            )}
          </Typography>
        </>
      )}
    </Box>
  );
};

export const Address = (props: {
  branch: BranchType;
  // loadingStatus: boolean;
  // lat?: number;
  // lng?: number;
  // address?: string;
  // customerService?: { id: number; num: string }[];
  // whatsapp?: { id: number; num: string }[];
  // fax?: { id: number; num: string }[];
  // email?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "30vh",
          backgroundColor: "rgba(0,0,0,0.2)",
          margin: {
            sm: "3rem 0 0 0",
            xs: "3rem 0 0 0",
            md: "0",
            lg: "0",
            xl: "0",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <MyMap
          markerLat={Number(props.branch?.latitude!)}
          markerLng={Number(props.branch?.longitude!)}
        />
        <Button
          variant="outlined"
          target={"_blank"}
          href={
            "https://www.google.com/maps/search/" +
            props.branch?.latitude! +
            "," +
            props.branch?.longitude!
          }
          sx={{ position: "absolute", left: "1rem", top: "4rem" }}
        >
          Open Maps
        </Button>
      </Box>
      <AddressSections branch={props.branch} />
      {props.branch?.bussiness.length > 0 &&
        props.branch?.bussiness.map((time) => (
          <>
            <Typography variant="h6">{t("work-time")}</Typography>
            <Typography variant="body1">
              {t("from") +
                ": " +
                getLanguageBasedDay(time.bussinessDayFrom) +
                ", " +
                t("to") +
                ": " +
                getLanguageBasedDay(time.bussinessDayTo) +
                " | " +
                "( " +
                time.bussinessHoursFrom +
                " " +
                t("to") +
                " " +
                time.bussinessHoursTo +
                " )"}
            </Typography>
          </>
        ))}
    </Box>
  );
};

export const AddressSections = (props: { branch: BranchType }) => {
  const { t } = useTranslation();
  return (
    <>
      <AddressSection
        icon={<LocationOnOutlined fontSize="small" />}
        label={t("address")}
        details={[
          {
            id: 0,
            num:
              i18n.language === "en-US"
                ? props.branch?.address
                : props.branch?.addressAr,
          },
        ]}
      />
      <AddressSection
        icon={<PhoneOutlined fontSize="small" />}
        label={t("customer-service")}
        details={props.branch?.customerServicesPhones}
      />
      <AddressSection
        icon={<WhatsApp fontSize="small" />}
        label={t("whatsapp")}
        details={props.branch?.whatsApp}
      />
      <AddressSection
        icon={<FaxOutlined fontSize="small" />}
        label={t("fax")}
        details={props.branch?.fax}
      />
      <AddressSection
        icon={<MailOutlineOutlined fontSize="small" />}
        label={t("email")}
        details={[{ id: 0, num: props.branch?.email }]}
      />
    </>
  );
};
