import { Typography, Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState, useTransition } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import { PermissionsController } from "../../components/permissions_controller";
import SectionHeader from "../../components/section_header";
import SpiningLogo from "../../components/spining_logo";
import { TextInput } from "../../components/text_input";
import { MODE, User } from "../../const";
import LocaleContext from "../../LocaleContext";
import { getRequest, postRequest, putRequest } from "../../network/api-client";
import { flexableParentBoxSX } from "../../styles";

const AddOrEditUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState<User>();
  const [mode, setMode] = useState<MODE>(userId ? MODE.EDIT : MODE.ADD);
  const { register, handleSubmit, reset } = useForm();
  const [isUserSubmitting, setIsUserSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    setIsUserSubmitting(true);
    mode === MODE.ADD &&
      (await postRequest("users/", data).then(
        (response) => {
          setIsUserSubmitting(false);
          navigate("/admin/users");
        },
        (error) => {
          setIsUserSubmitting(false);
        }
      ));
    mode === MODE.EDIT &&
      (await putRequest("users/" + userId, data).then(
        (response) => {
          setIsUserSubmitting(false);
          navigate("/admin/users");
        },
        (error) => {
          setIsUserSubmitting(false);
        }
      ));
  };

  const fetchUser = async () => {
    mode === MODE.EDIT &&
      (await getRequest("users/" + userId).then((res) => {
        setUser(res.user);
        reset({ username: res?.user?.username, email: res?.user?.email });
      }));
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Box sx={{ ...flexableParentBoxSX, height: "100vh" }}>
      <Box
        sx={{
          width:
            mode === MODE.EDIT && userId
              ? { xs: "100%", sm: "100%", md: "100%", lg: "50%" }
              : "100%",
        }}
      >
        <SectionHeader
          title={mode === MODE.ADD ? t("add-user") : t("edit-user")}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            title={t("username")}
            type="string"
            required
            value={user?.username}
            registeration={{
              ...register("username"),
            }}
            errorMessage={t("enter-username")}
          />
          <TextInput
            title={t("email")}
            type="email"
            required
            value={user?.email}
            registeration={{
              ...register("email"),
            }}
            errorMessage={t("enter-email")}
          />
          {mode === MODE.ADD && (
            <TextInput
              title={t("password")}
              type="password"
              required
              reset={reset}
              registeration={{ ...register("password") }}
              errorMessage={t("enter-password")}
            />
          )}
          <Button type="submit" variant="outlined" fullWidth>
            {isUserSubmitting ? (
              <SpiningLogo height="20px" />
            ) : mode === MODE.ADD ? (
              t("add-user")
            ) : (
              t("edit-user")
            )}
          </Button>
        </form>
      </Box>
      {mode === MODE.EDIT && userId && (
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" },
            p: "2rem",
          }}
        >
          <PermissionsController userId={userId} />
        </Box>
      )}
    </Box>
  );
};

export default AddOrEditUser;
