import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { SERVER_TYPE, TYPE } from "../const";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import {
  getRequest,
  handleApiClientError,
  postRequest,
} from "../network/api-client";
import { text_input_box_style } from "../styles";
import SpiningLogo from "./spining_logo";
import { TextInput } from "./text_input";
import Transition from "./transition";

const PricingDialog = (props: { open: boolean; handleClose: () => void }) => {
  const { handleSubmit, register, reset } = useForm();
  const [insuranceServicesIdAndName, setInsuranceServicesIdAndName] =
    useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const { type } = useContext(LocaleContext);
  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    setIsSubmiting(true);
    data = {
      ...data,
      productId: selectedProduct.code,
      phone: parseInt(data.phone),
      mobile: parseInt(data.mobile),
    };
    console.log(
      "princing form => name: " +
        data.name +
        " , civilID: " +
        data.civilID +
        " , email: " +
        data.email +
        " , details: " +
        data.details +
        " , phone: " +
        data.phone +
        " , mobile: " +
        data.mobile +
        " , productId: " +
        data.productId
    );
    Swal.fire({
      icon: "info",
      title: t("are-you-sure"),
      text: t("pricing-request"),
      confirmButtonText: t("ok"),
      showCancelButton: true,
      confirmButtonColor: "primary",
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postRequest("quote", data).then(
          (res) => {
            if (res) {
              Swal.fire({
                title: "",
                text: res.detail as string,
                icon: "success",
                background: "#fff",
                color: "#000",
                confirmButtonText: t("ok"),
                // confirmButtonColor: primaryColor,
              });
              setIsSubmiting(false);
              reset();
              setSelectedProduct(null);
              props.handleClose();
            }
          },
          (error) => {
            handleApiClientError(error);
            setIsSubmiting(false);
          }
        );
      }
    });
  };

  const fetchInsuranceServices = async () => {
    setIsServicesLoading(true);
    const prodType =
      type === TYPE.INDIVIDUALS
        ? SERVER_TYPE.INDIVIDUALS
        : SERVER_TYPE.CORPORATES;
    await getRequest("products/" + prodType).then(
      (res) => {
        setInsuranceServicesIdAndName(
          res.products.map((product: any) => {
            return {
              code: product.id,
              name: i18n.language === "en-US" ? product.title : product.titleAr,
            };
          })
        );
        setIsServicesLoading(false);
      },
      (error) => {
        setIsServicesLoading(false);
      }
    );
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    fetchInsuranceServices();
  }, []);

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="get-a-quote"
      fullWidth
      sx={{ zIndex: 900 }}
    >
      <DialogTitle>{t("get-a-quote")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
        <DialogContent
        // sx={{ width: "100%" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Box sx={text_input_box_style}>
                <TextInput
                  title={t("customer-name")}
                  errorMessage={""}
                  required={true}
                  registeration={{ ...register("name") }}
                />
              </Box>{" "}
              <Divider orientation="vertical" sx={{ width: "5%" }} />
              <Box sx={text_input_box_style}>
                <TextInput
                  title={t("customer-civil-id")}
                  errorMessage={""}
                  type="number"
                  required={true}
                  registeration={{ ...register("civilID") }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Box sx={text_input_box_style}>
                <TextInput
                  title={t("email")}
                  type="email"
                  errorMessage={""}
                  required={true}
                  registeration={{ ...register("email") }}
                />
              </Box>
              <Divider orientation="vertical" sx={{ width: "5%" }} />
              <Box sx={text_input_box_style}>
                <TextInput
                  title={t("phone-number")}
                  type="number"
                  errorMessage={""}
                  required={true}
                  registeration={{ ...register("phone") }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Box sx={text_input_box_style}>
                <TextInput
                  title={t("mobile-number")}
                  type="number"
                  errorMessage={""}
                  required={true}
                  registeration={{ ...register("mobile") }}
                />
              </Box>
              <Divider orientation="vertical" sx={{ width: "5%" }} />
              <Box sx={text_input_box_style}>
                <TextInput
                  title={t("select-service")}
                  errorMessage={""}
                  required={true}
                  disabled={isServicesLoading}
                  list={insuranceServicesIdAndName}
                  onChangeCapture={setSelectedProduct}
                  registeration={{ ...register("productId") }}
                />
              </Box>
            </Box>
            {selectedProduct && (
              <>
                <Divider
                  orientation="horizontal"
                  sx={{ height: "5%", backgroundColor: "#796328" }}
                />
                <Typography variant="h5">{selectedProduct.name}</Typography>
                <TextInput
                  title={t("enquiry-comments")}
                  errorMessage={""}
                  required={true}
                  height={"120px"}
                  multiLine={true}
                  rows={5}
                  registeration={{ ...register("details") }}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={isSubmiting}
            size="large"
          >
            {isSubmiting ? <SpiningLogo height="20px" /> : t("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PricingDialog;
