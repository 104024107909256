import { createTheme } from "@mui/material/styles";
import { light } from "@mui/material/styles/createPalette";
import i18n from "../i18n";

const getTheme = (theme = "LIGHT") => {
  return createTheme({
    breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 } },
    palette: {
      //   theme === "DARK" ? "dark" : "light",
      mode: theme === "DARK" ? "dark" : "light",
      primary: {
        main: "#931A25",
      },
      secondary: {
        main: "#790000",
      },
    },
    direction: i18n.dir(),
    typography: {
      fontFamily: ["Tajawal"].join(","),
      h1: {
        color: "#931A25",
      },
      h2: {
        color: "#931A25",
      },
      h3: {
        color: "#931A25",
      },
      h4: {
        color: "#931A25",
      },
      h5: {
        color: "#931A25",
      },
      h6: {
        color: "black",
      },
      subtitle1: {
        color: "black",
      },
      subtitle2: {
        color: "black",
      },
      body2: {
        color: "black",
      },
    },
  });
};

export default getTheme;
