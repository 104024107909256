import HomeRouter from "./pages/home_router";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NewLineKind } from "typescript";
import NavigationBar from "./components/nav_bar";
import CustomerService from "./pages/customer_service";
import i18n from "./i18n";
import { Suspense, useContext, useEffect, useState } from "react";
import Loading from "./components/loading";
import LocaleContext from "./LocaleContext";
import { Box, Container, CssBaseline, ThemeProvider } from "@mui/material";
import getTheme from "./components/layout";
import { isAdmin, TYPE } from "./const";
import Home from "./pages/home";
import Footer from "./components/footer";
import WrongPath from "./pages/404";
import Branches from "./pages/branches";
import Login from "./pages/admin-panel/login";
import ResetPassword from "./pages/admin-panel/reset_password";
import Users from "./pages/admin-panel/users";
import AddOrEditUser from "./pages/admin-panel/add_edit_user";
import AddOrEditInsuranceService from "./pages/admin-panel/add_edit_insurance_service";
import PricingRequests from "./pages/admin-panel/pricing_requests";
import Claims from "./pages/admin-panel/claims";
import AddOrEditBranch from "./pages/admin-panel/add_edit_branch";
import InsuranceService from "./components/insurance_service";
import CustomerServicesPanel from "./pages/admin-panel/customer_service_admin";

function App() {
  const [locale, setLocale] = useState(i18n.language);
  const [type, setType] = useState(
    sessionStorage.getItem("type") ?? TYPE.WITHOUT.toString()
  );
  document.dir = i18n.dir();

  sessionStorage.setItem("type", type);

  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  return (
    <LocaleContext.Provider value={{ locale, type, setLocale, setType }}>
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={getTheme()}>
          <Box
          // sx={{ margin: { xs: "0", sm: "0", md: "0 5rem 0 5rem" } }}
          >
            <CssBaseline />
            <BrowserRouter>
              {type != TYPE.WITHOUT &&
                window.location.pathname != "/admin/login" &&
                window.location.pathname != "/reset-password" && (
                  <NavigationBar />
                )}
              <Routes>
                {type != TYPE.WITHOUT ? (
                  <>
                    {/*TODO nav bar has bug! */}
                    <Route path={"*"} element={<WrongPath />} />
                    <Route path={"/home"} element={<Home />} />
                    <Route path={"/branches"} element={<Branches />} />
                    <Route path={"/"} element={<Home />} />
                    <Route
                      path={"/customer-service"}
                      element={<CustomerService />}
                    />
                    <Route path="/:serviceId" element={<InsuranceService />} />
                  </>
                ) : (
                  <>
                    <Route path={"*"} element={<WrongPath />} />
                    <Route path={"/"} element={<HomeRouter />} />
                    <Route path={"/home"} element={<HomeRouter />} />
                  </>
                )}

                <Route path={"/admin/login"} element={<Login />} />
                <Route path={"/reset-password"} element={<ResetPassword />} />
                {isAdmin && (
                  <>
                    <Route path={"/admin/users"} element={<Users />} />
                    <Route
                      path={"/admin/add-user"}
                      element={<AddOrEditUser />}
                    />
                    <Route
                      path={"/admin/edit-user/:userId"}
                      element={<AddOrEditUser />}
                    />
                    <Route
                      path={"/admin/add-insurance-service"}
                      element={<AddOrEditInsuranceService />}
                    />
                    <Route
                      path={"/admin/edit-insurance-service/:serviceId"}
                      element={<AddOrEditInsuranceService />}
                    />
                    <Route
                      path={"/admin/pricing-requests"}
                      element={<PricingRequests />}
                    />
                    <Route path={"/admin/claims"} element={<Claims />} />
                    <Route
                      path={"/admin/add-branch"}
                      element={<AddOrEditBranch />}
                    />
                    <Route
                      path={"/admin/edit-branch/:branchId"}
                      element={<AddOrEditBranch />}
                    />
                    <Route
                      path={"/admin/customer-services"}
                      element={<CustomerServicesPanel />}
                    />
                  </>
                )}
              </Routes>
            </BrowserRouter>
          </Box>
          <Footer />
        </ThemeProvider>
      </Suspense>
    </LocaleContext.Provider>
  );
}

export default App;
