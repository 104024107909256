import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";

import { TextInput } from "../../components/text_input";

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ExpertiseLogo from "../../logo.svg";

import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { handleApiClientError, postRequest } from "../../network/api-client";
import { useContext, useState } from "react";
import LocaleContext from "../../LocaleContext";
import Loading from "../../components/loading";
import SpiningLogo from "../../components/spining_logo";

export default function Login() {
  const { handleSubmit, register } = useForm();
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    setIsLoginLoading(true);
    const response = await postRequest("users/login", data).finally(() =>
      setIsLoginLoading(false)
    );
    isRememberMe
      ? localStorage.setItem("token", response.token)
      : sessionStorage.setItem("token", response.token);
    window.location.href = "/";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRememberMe(event.target.checked);
  };

  return (
    <Box
      sx={{
        padding: "0 10% 0 10%",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row-reverse",
          lg: "row-reverse",
        },
        width: "100%",
        height: "100vh",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "50%",
          p: "1rem ",
        }}
      >
        <img src={ExpertiseLogo} alt="Expertise Logo" width="100%" />
      </Box>
      <Box style={{ padding: "8% 0 10% 0" }}>
        <form
          style={{
            width: "50%",
            minWidth: "350px",
            borderRadius: "2em",
            padding: "3em",
            background: "inherit",
            boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
          }}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="new-password"
        >
          <Typography gutterBottom variant="h5" component="div">
            {t("login")}
          </Typography>
          <br />
          <TextInput
            title={t("username")}
            errorMessage={"ادخل اسم المستخدم"}
            required={true}
            registeration={{ ...register("email") }}
          />
          <TextInput
            title={t("password")}
            errorMessage={"يجب ادخال كلمة السر"}
            required={true}
            type="password"
            inputProps={{
              autoComplete: "new-password",
            }}
            registeration={{ ...register("password") }}
          />
          <FormControlLabel
            control={<Checkbox onChange={handleChange} value={isRememberMe} />}
            label="Remember me"
          />
          <Button
            fullWidth
            type="submit"
            onClick={() => {}}
            variant="outlined"
            disabled={isLoginLoading}
            size="large"
          >
            {isLoginLoading ? <SpiningLogo height="20px" /> : t("login")}
          </Button>
          <Typography>
            forget password? <a href="/reset-password">Reset password</a>
          </Typography>
        </form>
      </Box>
    </Box>
  );
}
