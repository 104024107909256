import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { TextInput } from "../../components/text_input";
import { postRequest } from "../../network/api-client";
import ExpertiseLogo from "../../logo.svg";
import { useNavigate } from "react-router-dom";
import SpiningLogo from "../../components/spining_logo";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const [email, setEmail] = useState();
  const [userId, setUserId] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [resetCode, setResetCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const steps = [
    {
      label: "Enter your email",
      description: "",
      stepContent: (
        <>
          <TextInput
            setValueCode={setEmail}
            required
            errorMessage="Please enter your email"
            placeholder="Enter your Email"
          />
        </>
      ),
      onStepContinue: async (index: number) => {
        setIsLoading(true);
        const resposnse: any =
          email &&
          (await postRequest("users/getRestPasswordCode", {
            email: email,
          }).finally(() => setIsLoading(false)));
        if (resposnse) {
          setUserId(resposnse?.userId);
          handleNext(index);
        }
      },
    },
    {
      label: "Email verification",
      description: "verification code sent to your email",
      stepContent: (
        <>
          <TextInput
            setValueCode={setVerificationCode}
            required
            maxLength={8}
            type="number"
            errorMessage="Please enter your email"
            placeholder="Enter 8 digits code"
          />
        </>
      ),
      onStepContinue: async (index: number) => {
        setIsLoading(true);
        const resposnse: any = await postRequest(
          "users/verifyRestPasswordCode",
          { userId: userId, code: verificationCode }
        ).finally(() => setIsLoading(false));
        if (resposnse) {
          setResetCode(resposnse?.resetId);
          handleNext(index);
        }
      },
    },
    {
      label: "Enter new password",
      description: "",
      stepContent: (
        <>
          <TextInput
            setValueCode={setNewPassword}
            required
            type="password"
            errorMessage="Please enter your new password"
            placeholder="Enter your new password"
          />
          <TextInput
            setValueCode={setConfirmNewPassword}
            required
            type="password"
            errorMessage="Please confirm your new password"
            placeholder="Confirm your new password"
          />
        </>
      ),
      onStepContinue: async (index: number) => {
        setIsLoading(true);
        if (newPassword === confirmNewPassword) {
          const resposnse: any = await postRequest("users/resetpassword", {
            resetId: resetCode,
            newPassword: newPassword,
          }).finally(() => setIsLoading(false));
          if (resposnse) {
            Swal.fire({
              icon: "success",
              text: "" + resposnse.detail,
              confirmButtonText: "حسنًا",
              confirmButtonColor: "#009688",
              background: "#fff",
              color: "#000",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.pathname = "/admin/login";
              }
            });
            console.log("response: " + resposnse.detail);
          }
        }
      },
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (step: number) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "0 1% 0 1%",
          sm: "0 1% 0 1%",
          md: "0 10% 0 10%",
          lg: "0 10% 0 10%",
        },
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row-reverse",
          lg: "row-reverse",
        },
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        onClick={() => (window.location.href = "/")}
        sx={{
          width: "50%",
          p: "1rem ",
          cursor: "pointer",
        }}
      >
        <img src={ExpertiseLogo} alt="Expertise Logo" width="100%" />
      </Box>
      <Box
        sx={{
          padding: "3rem",
          width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
        }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <form>
                    {step.stepContent}
                    <div>
                      <Button
                        variant="contained"
                        // type="submit"
                        disabled={isLoading}
                        onClick={() => {
                          step.onStepContinue(index);
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {isLoading ? (
                          <SpiningLogo height={"20px"} />
                        ) : index === steps.length - 1 ? (
                          "Finish"
                        ) : (
                          "Continue"
                        )}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </form>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default ResetPassword;
