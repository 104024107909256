import { Box } from "@mui/material";

export enum TYPE {
  INDIVIDUALS = "INDIVIDUALS",
  CORPORATES = "CORPORATES",
  WITHOUT = "WITHOUT",
}

export const mapsAPIKey = "AIzaSyACOzSSKwyB61cZV0GQknxUSyDB5Y6NCuY";

export enum SERVER_TYPE {
  INDIVIDUALS = "individual",
  CORPORATES = "companies",
}

export enum MODE {
  ADD = "ADD",
  EDIT = "EDIT",
  SHOW = "SHOW",
}

export type User = { email: string; username: string; password: string };

export type SubDescription = {
  id?: number;
  description?: string;
  title?: string;
  titleAr?: string;
  descriptionAr?: string;
};

export type InsuranceServiceType = {
  title: string;
  titleAr: string;
  image_url: string;
  type: SERVER_TYPE;
  mainDescriptionEn: string;
  mainDescriptionAr: string;
  description: SubDescription[];
};

export type BranchType = {
  id: number;
  address: string;
  addressAr: string;
  email: string;
  customerServicesPhones: { id: number; num: string }[];
  whatsApp: { id: number; num: string }[];
  fax: { id: number; num: string }[];
  latitude: string;
  longitude: string;
  bussiness: [
    {
      id: number;
      bussinessHoursFrom: string;
      bussinessHoursTo: string;
      bussinessDayFrom: string;
      bussinessDayTo: string;
    }
  ];
};

export const Days = [
  { code: "saturday", name: "saturday" },
  { code: "sunday", name: "sunday" },
  { code: "monday", name: "monday" },
  { code: "tuesday", name: "tuesday" },
  { code: "wednesday", name: "wednesday" },
  { code: "thursday", name: "thursday" },
  { code: "friday", name: "friday" },
];

export enum EnDay {
  saturday = "saturday",
  sunday = "sunday",
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
}

export enum ArDay {
  saturday = "السبت",
  sunday = "الأحد",
  monday = "الإثنين",
  tuesday = "الثلاثاء",
  wednesday = "الأربعاء",
  thursday = "الخميس",
  friday = "الجمعة",
}

export type BusinessHours = {
  id?: string;
  bussinessHoursFrom: string;
  bussinessHoursTo: string;
  bussinessDayFrom: string;
  bussinessDayTo: string;
};

export const baseColumnProperities: any = {
  field: "",
  headerName: "",
  headerAlign: "left",
  type: "string",
  align: "left",
  flex: 1,
};

export const filterTranslations = {
  // Root
  noRowsLabel: "لا توجد صفوف",
  noResultsOverlayLabel: "لم يتم العثور على نتائج.",
  errorOverlayDefaultLabel: "حدث خطأ.",

  // Density selector toolbar button text
  toolbarDensity: "كثافة",
  toolbarDensityLabel: "كثافة",
  toolbarDensityCompact: "مدمج",
  toolbarDensityStandard: "المعيار",
  toolbarDensityComfortable: "مريح",

  // Columns selector toolbar button text
  toolbarColumns: "الأعمدة",
  toolbarColumnsLabel: "حدد الأعمدة",

  // Filters toolbar button text
  toolbarFilters: "تنقية و بحث",
  toolbarFiltersLabel: "اظهر الفلاتر",
  toolbarFiltersTooltipHide: "إخفاء الفلاتر",
  toolbarFiltersTooltipShow: "اظهر الفلاتر",
  toolbarFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} فلاتر نشطة` : `${count} فلاتر نشطة`,

  // Export selector toolbar button text
  toolbarExport: "تصدير",
  toolbarExportLabel: "تصدير",
  toolbarExportCSV: "تنزيل كملف CSV",
  // toolbarExportPrint: 'Print',

  // Columns panel text
  columnsPanelTextFieldLabel: "ايجاد عمود",
  columnsPanelTextFieldPlaceholder: "عنوان العمود",
  columnsPanelDragIconLabel: "إعادة ترتيب العمود",
  columnsPanelShowAllButton: "عرض الكل",
  columnsPanelHideAllButton: "اخفاء الكل",

  // Filter panel text
  filterPanelAddFilter: "اضف فلتر",
  filterPanelDeleteIconLabel: "حذف",
  filterPanelOperators: "العاملين",
  filterPanelOperatorAnd: "و",
  filterPanelOperatorOr: "او",
  filterPanelColumns: "الأعمدة",
  filterPanelInputLabel: "قيمة",
  filterPanelInputPlaceholder: "قيمة التصفية",

  // Filter operators text
  filterOperatorContains: "يحتوي على",
  filterOperatorEquals: "يساوي",
  filterOperatorStartsWith: "يبدأ ب",
  filterOperatorEndsWith: "ينتهي بـ",
  filterOperatorIs: "يكون",
  filterOperatorNot: "ليس",
  filterOperatorAfter: "يكون بعد",
  filterOperatorOnOrAfter: "في أو بعد",
  filterOperatorBefore: "يكون",
  filterOperatorOnOrBefore: "في أو قبل",
  filterOperatorIsEmpty: "فارغ",
  filterOperatorIsNotEmpty: "ليس فارغا",
  // filterOperatorIsAnyOf: 'is any of',

  // Filter values text
  filterValueAny: "أي",
  filterValueTrue: "صحيح",
  filterValueFalse: "خاطئة",

  // Column menu text
  columnMenuLabel: "القائمة",
  columnMenuShowColumns: "إظهار الأعمدة",
  columnMenuFilter: "فلتر",
  columnMenuHideColumn: "إخفاء",
  columnMenuUnsort: "غير مرتب",
  columnMenuSortAsc: "الترتيب حسب ASC",
  columnMenuSortDesc: "الترتيب حسب DESC",

  // Column header text
  columnHeaderFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} فلاتر نشطة` : `${count} فلاتر نشطة`,
  columnHeaderFiltersLabel: "اظهر الفلاتر",
  columnHeaderSortIconLabel: "ترتيب",

  // Rows selected footer text
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} صفوف مختارة`
      : `${count.toLocaleString()} صفوف مختارة`,

  // Total rows footer text
  footerTotalRows: "إجمالي الصفوف:",

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount: any, totalCount: any) =>
    `${visibleCount.toLocaleString()} من ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "تحديد",

  // Boolean cell text
  booleanCellTrueLabel: "صحيح",
  booleanCellFalseLabel: "خاطئة",
};

export const flatPathes = ["/admin/login", "/reset-password"];

const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");

export const isAdmin = token ? true : false;
