// import { useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
// import LocaleContext from "../LocaleContext";

const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");

const axiosClient = axios.create({
  baseURL: `https://insurance-project-fslq.onrender.com/`,
  headers: {
    Accept: "application/json",
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  },
});

const axiosFormDataClient = axios.create({
  baseURL: `https://insurance-project-fslq.onrender.com/`,
  headers: {
    Accept: "multipart/form-data",
    Authorization: "Token " + token,
    "Content-Type": "multipart/form-data",
  },
});

// // axiosClient.defaults.timeout = 20000;

// axiosClient.defaults.withCredentials = true;

export async function getRequest(URL: string) {
  const response = await axiosClient.get(`/${URL}`);
  return response?.data;
}

export async function postRequest(
  URL: string,
  payload: any,
  isFormData = false
) {
  if (isFormData) {
    const response = await axiosFormDataClient.post(`/${URL}`, payload);
    return response?.data;
  } else {
    const response = await axiosClient.post(`/${URL}`, payload);
    return response?.data;
  }
}

export async function patchRequest(URL: string, payload: any) {
  const response = await axiosClient.patch(`/${URL}`, payload);
  return response?.data;
}

export async function putRequest(
  URL: string,
  payload: any,
  isFormData = false
) {
  if (isFormData) {
    const response = await axiosFormDataClient.put(`/${URL}`, payload);
    return response?.data;
  } else {
    const response = await axiosClient.put(`/${URL}`, payload);
    return response?.data;
  }
}

export async function deleteRequest(URL: string) {
  const response = await axiosClient.delete(`/${URL}`);
  return response?.data;
}

axiosClient.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    handleApiClientError(error);
  }
);

export const handleApiClientError = (error: any) => {
  let res = error.response;
  var errorMessage = "! حدث خطأ، يرجى المحاولة لاحقًا";

  if (res) {
    // Request made and server responded
    errorMessage = res?.data?.detail
      ? res?.data?.detail
      : res?.data?.details
      ? res?.data?.details
      : JSON.stringify(res?.data);
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = error?.request;
  } else if (error?.message) {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error?.message;
  } else {
    errorMessage = "" + error;
  }
  if ((error.message as string).includes("Network")) {
    errorMessage = "! حدث خطأ، يرجى التأكد من الاتصال بالشبكة ";
  }
  if (res?.statusText.includes("Unauth")) {
    sessionStorage.clear();
    window.location.reload();
    return;
  }
  Swal.fire({
    icon: "error",
    title: "! خطأ",
    text: "" + errorMessage,
    confirmButtonText: "حسنًا",
    confirmButtonColor: "#009688",
    background: "#fff",
    color: "#000",
  });
  // if (res.status == 401) {
  //   window.location.pathname = "/admin/login";
  // }
  console.error("Looks like there was a problem. Status Code: " + res?.status);
  return Promise.reject(error);
};
