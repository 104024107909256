import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import LocaleContext from "../LocaleContext";
import {
  getRequest,
  handleApiClientError,
  putRequest,
} from "../network/api-client";
import {
  permissionControllerListTile,
  permissionsControllerGridStyle,
} from "../styles";
import Loading from "./loading";
import SpiningLogo from "./spining_logo";

const enum permissionAction {
  ADD = "add",
  REMOVE = "remove",
}

type permissionsControllerType = {
  userId: string;
};

export function PermissionsController(props: permissionsControllerType) {
  const { t } = useTranslation();
  const [userPermissions, setUserPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [isAllPermissionsLoading, setIsAllPermissionsLoading] = useState(false);
  const [isUserPermissionsLoading, setIsUserPermissionsLoading] =
    useState(false);
  const [isPermissionLoading, setIsPermissionLoading] = useState(false);

  const addOrRemovePermission = async (permissionId: string) => {
    setIsPermissionLoading(true);
    // Swal.fire({});
    const response = await putRequest("users/permission/" + props.userId, {
      permissionId: permissionId,
    });
    fetch();
    setIsPermissionLoading(false);
  };

  const fetchAllPermissions = async () => {
    setIsAllPermissionsLoading(true);
    await getRequest("users/permissions/allPermission").then(
      (res) => {
        setAllPermissions(res.permissions);
        setIsAllPermissionsLoading(false);
      },
      (error) => {
        setIsAllPermissionsLoading(false);
      }
    );
  };

  const fetchUserPermissions = async () => {
    setIsUserPermissionsLoading(true);
    await getRequest("users/permissions/" + props.userId).then(
      (res) => {
        setUserPermissions(res.userPermissions);
        setIsUserPermissionsLoading(false);
      },
      (error) => {
        handleApiClientError(error);
        setIsUserPermissionsLoading(false);
      }
    );
  };

  const fetch = () => {
    fetchUserPermissions();
    fetchAllPermissions();
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Grid
      container
      spacing={5}
      style={{
        justifyContent: "center",
        margin: "0%",
      }}
    >
      <Typography gutterBottom variant="h5">
        {t("edit-permissions")}
      </Typography>
      <Grid item xs={12} sx={permissionsControllerGridStyle}>
        <Typography variant="h6">{t("all-permissions")}</Typography>
        <Box
          style={{
            maxHeight: "200px",
            display: isAllPermissionsLoading ? "flex" : undefined,
            justifyContent: isAllPermissionsLoading ? "center" : undefined,
            alignItems: isAllPermissionsLoading ? "center" : undefined,
          }}
        >
          {isAllPermissionsLoading ? (
            <SpiningLogo height="50px" />
          ) : allPermissions.length < 1 ? (
            <Typography variant="subtitle2" textAlign={"center"}>
              {t("no-permissions")}
            </Typography>
          ) : (
            allPermissions?.map((permission: any, i) => (
              <Box sx={permissionControllerListTile}>
                <Typography>{permission?.permission_name}</Typography>
                <Button
                  style={{ backgroundColor: "#009688", color: "white" }}
                  onClick={() => addOrRemovePermission(permission?.id)}
                >
                  {isPermissionLoading ? (
                    <SpiningLogo height="15px" />
                  ) : (
                    t("add")
                  )}
                </Button>
              </Box>
            ))
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sx={permissionsControllerGridStyle}>
        <Typography variant="h6">{t("available-permissions")}</Typography>
        <Box
          style={{
            maxHeight: "200px",
            display: isUserPermissionsLoading ? "flex" : undefined,
            justifyContent: isUserPermissionsLoading ? "center" : undefined,
            alignItems: isUserPermissionsLoading ? "center" : undefined,
          }}
        >
          {isUserPermissionsLoading ? (
            <SpiningLogo height="50px" />
          ) : userPermissions.length < 1 ? (
            <Typography variant="subtitle2" textAlign={"center"}>
              {t("no-permissions")}
            </Typography>
          ) : (
            userPermissions?.map((permission: any, i) => (
              <Box sx={permissionControllerListTile}>
                <Typography>{permission?.permission_name}</Typography>
                <Button
                  style={{ backgroundColor: "#DC0A0A", color: "white" }}
                  onClick={() => addOrRemovePermission(permission?.id)}
                >
                  {isPermissionLoading ? (
                    <SpiningLogo height="15px" />
                  ) : (
                    t("delete")
                  )}
                </Button>
              </Box>
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
