import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TYPE } from "../const";
import LocaleContext from "../LocaleContext";
import ExpertiseLogo from "../logo.svg";
import hrp from "../images/hrp.jpeg";

export default function HomeRouter() {
  const navigate = useNavigate();
  const { type, setType } = useContext(LocaleContext);

  // useEffect(() => {
  //   // setType(TYPE.WITHOUT);
  //   // sessionStorage.setItem("type", TYPE.WITHOUT);
  // }, [type]);

  return (
    <div
      style={{
        height: "95vh",
        margin: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${hrp})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // padding: "1rem",
      }}
    >
      <div
        style={{
          height: "60vh",
          width: "70%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0, 0.15)",
          borderRadius: "2rem",
        }}
      >
        <div
          style={{
            height: "40%",
            width: "40%",
            paddingTop: "11em",
            backgroundImage: `url(${ExpertiseLogo})`,
            backgroundPosition: "center",
            // backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "40%",
            width: "50%",
          }}
        >
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="error"
            sx={{ color: "white" }}
            onClick={() => {
              setType(TYPE.INDIVIDUALS);
              sessionStorage.setItem("type", TYPE.INDIVIDUALS);
              navigate("/home");
            }}
          >
            الأفراد <br />
            INDIVIDUALS
          </Button>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            color="error"
            sx={{ color: "error" }}
            onClick={() => {
              setType(TYPE.CORPORATES);
              sessionStorage.setItem("type", TYPE.CORPORATES);
              navigate("/home");
            }}
          >
            الشركات <br /> CORPORATES
          </Button>
        </div>
      </div>
    </div>
  );
}
