import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useState, useContext, useEffect } from "react";
import Loading from "../../components/loading";
import SectionHeader from "../../components/section_header";
import { TextInput } from "../../components/text_input";
import LocaleContext from "../../LocaleContext";
import { getRequest, handleApiClientError } from "../../network/api-client";
import { gridStyle, parentBoxStyle } from "../../styles";

const CustomerServicesPanel = () => {
  const [customerServices, setCustomerServices] = useState([]);
  const [isCustomerServiceLoading, setIsCustomerServiceLoading] =
    useState(false);

  const fetchCustomerServices = async () => {
    setIsCustomerServiceLoading(true);
    const res = await getRequest("customer-service").finally(() =>
      setIsCustomerServiceLoading(false)
    );
    setCustomerServices(res.customerServices);
  };

  useEffect(() => {
    fetchCustomerServices();
  }, []);

  return (
    <Box sx={{ ...parentBoxStyle, height: "auto" }}>
      <SectionHeader
        title={t("customer-service")}
        // adminActions={[
        //   <Box sx={{ width: "200px" }}>
        //     <TextInput
        //       list={[
        //         { code: 1, name: "new" },
        //         { code: 2, name: "reviewed" },
        //       ]}
        //       optionValue={{ code: 1, name: "new" }}
        //     />
        //   </Box>,
        // ]}
      />
      {isCustomerServiceLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {customerServices?.map((customerServiceRequest: any, i: number) => (
            <Grid
              item
              xs={12}
              sx={{
                border: "2px solid #796328",
                borderRadius: "2rem",
                m: "0.5rem",
              }}
              key={i}
            >
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("customer-name") +
                    " : " +
                    customerServiceRequest.customerName}
                </Typography>
              </Box>
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("email") + " : " + customerServiceRequest.email}
                </Typography>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("mobile-number") + " : " + customerServiceRequest.mobile}
                </Typography>
              </Box>
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true}>
                  {t("message-comment") +
                    " : " +
                    customerServiceRequest.message}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
export default CustomerServicesPanel;
