import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import { postRequest, handleApiClientError } from "../network/api-client";
import SpiningLogo from "./spining_logo";
import { TextInput } from "./text_input";
import Transition from "./transition";

const ClaimsDialog = (props: { open: boolean; handleClose: () => void }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      policyNum: "",
      customerName: "",
      email: "",
      mobile: "",
      message: "",
    },
    shouldUnregister: false,
  });
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [isSafeToReset, setIsSafeToReset] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    // data = { ...data, mobile: parseInt(data.mobile) };
    // reset({
    //   policyNum: "",
    //   customerName: "",
    //   email: "",
    //   mobile: "",
    //   message: "",
    // });
    setIsClaimLoading(true);
    Swal.fire({
      icon: "info",
      title: t("are-you-sure"),
      text: t("file-a-claim"),
      confirmButtonText: t("ok"),
      showCancelButton: true,
      confirmButtonColor: "primary",
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postRequest("complains", data).then(
          (res) => {
            if (res) {
              setIsClaimLoading(false);
              reset({
                policyNum: "",
                customerName: "",
                email: "",
                mobile: "",
                message: "",
              });
              console.log("reset");
              Swal.fire({
                title: "",
                text: res.detail as string,
                icon: "success",
                background: "#fff",
                color: "#000",
                confirmButtonText: t("ok"),
                // confirmButtonColor: primaryColor,
              });
              props.handleClose();
            }
          },
          (error) => {
            handleApiClientError(error);
            setIsClaimLoading(false);
          }
        );
      }
    });
  };

  // useEffect(() => {
  //   console.log(isSafeToReset);
  //   if (!isSafeToReset) return;
  //   reset({
  //     policyNum: undefined,
  //     customerName: undefined,
  //     email: undefined,
  //     mobile: undefined,
  //     message: undefined,
  //   }); // asynchronously reset your form values
  // }, [reset]);

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="get-a-quote"
      sx={{ zIndex: 900 }}
      fullWidth
    >
      <DialogTitle>{t("file-a-claim")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
        <DialogContent sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <TextInput
              title={t("policy-no")}
              errorMessage={""}
              type="number"
              required={true}
              registeration={{ ...register("policyNum") }}
            />
            <TextInput
              title={t("customer-name")}
              errorMessage={""}
              required={true}
              registeration={{ ...register("customerName") }}
            />
            <TextInput
              title={t("email")}
              errorMessage={""}
              required={true}
              registeration={{ ...register("email") }}
            />
            <TextInput
              title={t("mobile-number")}
              errorMessage={""}
              type="number"
              required={true}
              registeration={{ ...register("mobile") }}
            />
            <Divider
              orientation="horizontal"
              sx={{ height: "5%", backgroundColor: "#796328" }}
            />
            <TextInput
              title={t("claim-details")}
              errorMessage={""}
              required={true}
              height={"120px"}
              multiLine={true}
              rows={5}
              registeration={{ ...register("message") }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isClaimLoading}
            fullWidth
            type="submit"
            variant="contained"
            size="large"
          >
            {isClaimLoading ? <SpiningLogo height="20px" /> : t("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ClaimsDialog;
