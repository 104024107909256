import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useState, useContext, useEffect } from "react";
import Loading from "../../components/loading";
import LoadingBox from "../../components/loading_box";
import SectionHeader from "../../components/section_header";
import { TextInput } from "../../components/text_input";
import LocaleContext from "../../LocaleContext";
import { getRequest, handleApiClientError } from "../../network/api-client";
import { gridStyle, parentBoxStyle } from "../../styles";

const Claims = () => {
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);

  const fetchClaims = async () => {
    setIsClaimsLoading(true);
    const res = await getRequest("complains");
    setClaims(res);
    setIsClaimsLoading(false);
  };

  useEffect(() => {
    fetchClaims();
  }, []);

  return (
    <Box sx={{ ...parentBoxStyle, height: "auto" }}>
      <SectionHeader
        title={t("claims-header")}
        // adminActions={[
        //   <Box sx={{ width: "200px" }}>
        //     <TextInput
        //       list={[
        //         { code: 1, name: "new" },
        //         { code: 2, name: "reviewed" },
        //       ]}
        //       optionValue={{ code: 1, name: "new" }}
        //     />
        //   </Box>,
        // ]}
      />
      {isClaimsLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingBox />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {claims?.map((claim: any, i: number) => (
            <Grid
              item
              xs={12}
              sx={{
                border: "2px solid #FFCB8E",
                borderRadius: "2rem",
                m: "0.5rem",
              }}
              key={i}
            >
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("customer-name") + " : " + claim?.customerName}
                </Typography>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("policy-no") + " : " + claim?.policyNum}
                </Typography>
              </Box>
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("email") + " : " + claim?.email}
                </Typography>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("mobile-number") + " : " + claim?.mobile}
                </Typography>
              </Box>
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true}>
                  {t("claim-details") + " : " + claim?.message}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
export default Claims;
