import { Box } from "@mui/material";
import e from "../images/1.png";
import w from "../images/2.png";

const SpiningLogo = (props: { height?: string }) => {
  return (
    <Box
      style={{
        flexGrow: "auto",
        backgroundImage: `url(${w})`,
        backgroundPosition: "center",
        backgroundSize: "30%",
        backgroundRepeat: "no-repeat",
        position: "relative",
        // width: "50px",
      }}
    >
      <img
        className="App-logo"
        src={e}
        alt="w"
        height={props.height ?? undefined}
      />
      {/* <img className="x" src={w} alt="e" /> */}
    </Box>
  );
};
export default SpiningLogo;
