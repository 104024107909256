import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loading";
import SectionHeader from "../../components/section_header";
import { TextInput } from "../../components/text_input";
import i18n from "../../i18n";
import LocaleContext from "../../LocaleContext";
import { getRequest, handleApiClientError } from "../../network/api-client";
import { gridStyle, parentBoxStyle } from "../../styles";

const PricingRequests = () => {
  const { t } = useTranslation();
  const [pricingRequests, setPricingRequests] = useState([]);
  const [isPricingRequestsLoading, setIsPricingRequestsLoading] =
    useState(false);

  const fetchPricingRequests = async () => {
    setIsPricingRequestsLoading(true);
    await getRequest("quote/").then(
      (res) => {
        if (res) {
          setPricingRequests(res.quotes);
          setIsPricingRequestsLoading(false);
        }
      },
      (error) => {
        handleApiClientError(error);
        setIsPricingRequestsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchPricingRequests();
  }, []);

  return (
    <Box sx={{ ...parentBoxStyle, height: "auto" }}>
      <SectionHeader
        title={t("pricing-request-header")}
        // adminActions={[
        //   <Box sx={{ width: "200px" }}>
        //     <TextInput
        //       list={[
        //         { code: 1, name: "new" },
        //         { code: 2, name: "reviewed" },
        //       ]}
        //       optionValue={{ code: 1, name: "new" }}
        //     />
        //   </Box>,
        // ]}
      />
      {isPricingRequestsLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {pricingRequests.map((request: any, i: number) => (
            <Grid
              item
              xs={12}
              sx={{
                border: "2px solid #FFCB8E",
                borderRadius: "2rem",
                m: "0.5rem",
              }}
              key={i}
            >
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("customer-name") + " : " + request?.name!}
                </Typography>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("customer-civil-id") + " : " + request?.civilID!}
                </Typography>
              </Box>
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("email") + " : " + request?.email!}
                </Typography>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("phone-number") + " : " + request?.phone!}
                </Typography>
              </Box>
              <Box sx={gridStyle}>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("mobile-number") + " : " + request?.mobile!}
                </Typography>
                <Typography variant="h6" noWrap={true} flex={1}>
                  {t("service-name") +
                    " : " +
                    (i18n.language === "en-US"
                      ? request?.product?.title!
                      : request?.product?.titleAr!)}
                </Typography>
              </Box>
              <Box sx={gridStyle} flex={1}>
                <Typography variant="h6" noWrap={true}>
                  {t("enquiry-comments") + " : " + request.details}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PricingRequests;
