import { Wrapper } from "@googlemaps/react-wrapper";
import {
  FacebookRounded,
  Instagram,
  LanguageRounded,
  LinkedIn,
  Twitter,
  WhatsApp,
  WhatshotRounded,
} from "@mui/icons-material";
import {
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BranchType, mapsAPIKey, SERVER_TYPE, TYPE } from "../const";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import { getRequest, handleApiClientError } from "../network/api-client";
import { Address, AddressSections } from "./address";
import LanguageSwitcher from "./language_switcher_button";
import MyMap from "./map";
import SpiningLogo from "./spining_logo";

const Footer = () => {
  const { t } = useTranslation();
  const [isHeadOfficeLoading, setIsHeadOfficeLoading] = useState(false);
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const { type } = useContext(LocaleContext);
  const [insuranceServices, setInsuranceServices] = useState<any>([]);
  const isVisible =
    type != TYPE.WITHOUT &&
    window.location.pathname != "/admin/login" &&
    window.location.pathname != "/reset-password";
  const [headOffice, setHeadOffice] = useState<BranchType>();

  const fetchHeadOffice = async () => {
    setIsHeadOfficeLoading(true);
    const res = await getRequest("branches/").finally(() =>
      setIsHeadOfficeLoading(false)
    );
    setHeadOffice(res.branches[0]);
  };

  useEffect(() => {
    fetchHeadOffice();
  }, []);

  return (
    <>
      <Paper
        sx={{
          position: "relative",
          left: 0,
          width: "100%",
          bottom: 0,
          right: 0,
          backgroundColor: "#FFCB8E",
          //   zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        elevation={3}
      >
        <Box
          sx={{
            display: isVisible ? "flex" : "none",
            flexDirection: {
              sm: "column",
              xs: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: "space-between",
            padding: { xs: "1rem", sm: "1rem", md: "5rem" },
          }}
        >
          <Box
            sx={{
              width: {
                sm: "100%",
                xs: "100%",
                md: "20%",
                lg: "20%",
                xl: "20%",
              },
              height: "15vh",
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              justifyContent: "space-between",
              margin: {
                sm: "3rem",
                xs: "3rem",
                md: i18n.language === "en-US" ? "0 1rem 0 0" : "0 0 0 1rem",
                lg: i18n.language === "en-US" ? "0 1rem 0 0" : "0 0 0 1rem",
                xl: i18n.language === "en-US" ? "0 1rem 0 0" : "0 0 0 1rem",
              },
            }}
          >
            <Box>
              <Typography variant="h4" textAlign={"center"}>
                {"EXPERTISE"}
              </Typography>
              <Typography variant="h4" textAlign={"center"}>
                {"INSURANCE"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "auto",
                justifyContent: "center",
              }}
            >
              <IconButton
                href="https://www.instagram.com/expertise.insurance"
                target="_blank"
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="http://api.whatsapp.com/send?phone=96555580182"
                target="_blank"
              >
                <WhatsApp />
              </IconButton>
              <IconButton href="https://twitter.com/exp_insur" target="_blank">
                <Twitter />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                sm: "100%",
                xs: "100%",
                lg: "30%",
                xl: "30%",
                md: "30%",
              },
              display: "flex",
              flexDirection: "row",
              margin: {
                sm: "3rem 0 0 0",
                xs: "3rem 0 0 0",
                lg: "0",
                md: "0",
                xl: "0",
              },
            }}
          >
            <Services type={SERVER_TYPE.INDIVIDUALS} />
            <Services type={SERVER_TYPE.CORPORATES} />
          </Box>
          <Box
            sx={{
              width: {
                sm: "100%",
                xs: "100%",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
              height: "30vh",
              backgroundColor: "rgba(0,0,0,0.2)",
              margin: {
                sm: "3rem 0 0 0",
                xs: "3rem 0 0 0",
                md: "0",
                lg: "0",
                xl: "0",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {isHeadOfficeLoading ? (
              <SpiningLogo height="50px" />
            ) : (
              <>
                {headOffice?.longitude !== ("NaN" && undefined) &&
                  headOffice?.latitude !== ("NaN" && undefined) && (
                    <MyMap
                      markerLat={Number(headOffice?.latitude!)!}
                      markerLng={Number(headOffice?.longitude!)!}
                    />
                  )}
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.3)",
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {headOffice?.latitude && headOffice?.longitude && (
                    <Button
                      variant="contained"
                      href={
                        "https://www.google.com/maps/search/" +
                        headOffice?.latitude! +
                        "," +
                        headOffice?.longitude!
                      }
                      // sx={{ position: "absolute", left: "1rem", top: "4rem" }}
                    >
                      Open Maps
                    </Button>
                  )}
                  {headOffice?.address !== undefined &&
                    headOffice?.email !== undefined && (
                      <AddressSections branch={headOffice} />
                    )}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Typography
          variant="subtitle2"
          align="center"
          padding={2}
          // color="white"
        >
          {i18n.language === "en-US"
            ? "@ EXPERTISE INSURANCE 2022"
            : "EXPERTISE INSURANCE 2022 @"}
        </Typography>
      </Paper>
    </>
  );
};

export default Footer;

const Services = (props: { type: SERVER_TYPE }) => {
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const [insuranceServices, setInsuranceServices] = useState<any>([]);
  const typeT =
    props.type === SERVER_TYPE.INDIVIDUALS ? TYPE.INDIVIDUALS : TYPE.CORPORATES;
  const { t } = useTranslation();

  const fetchInsuranceServices = async () => {
    setIsServicesLoading(true);
    const prodType = props.type;
    await getRequest("products/" + prodType).then(
      (res) => {
        setInsuranceServices(res.products);
        setIsServicesLoading(false);
      },
      (error) => {
        handleApiClientError(error);
        setIsServicesLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchInsuranceServices();
  }, []);

  return (
    <Box sx={{ width: "50%", mx: "1rem", height: "100%" }}>
      <Typography variant="h5">
        {i18n.language === "en-US"
          ? t(`${t(typeT)?.toLowerCase()}`)
          : t(`${typeT?.toLowerCase()}`)}
      </Typography>
      <br />
      {!isServicesLoading ? (
        insuranceServices.map((service: any) => (
          <Button
            fullWidth
            sx={{ justifyContent: "start" }}
            variant="text"
            href={"/" + service.id}
          >
            {i18n.language === "en-US" ? service.title : service.titleAr}
          </Button>
        ))
      ) : (
        <Box
          sx={{
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpiningLogo height="50px" />
        </Box>
      )}
    </Box>
  );
};
