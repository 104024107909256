import React from "react";
import { TYPE } from "./const";

const defaultValue = {
  locale: "en",
  type: TYPE.WITHOUT.toString(),
  setLocale: (value) => {},
  setType: (value) => {},
};

export default React.createContext(defaultValue);
