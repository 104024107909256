import { LanguageRounded } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";

export default function LanguageSwitcher() {
  const { locale, type } = useContext(LocaleContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function changeLocale(l: any) {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  }
  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <LanguageRounded
          sx={{
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
            },
          }}
        />
      </IconButton>
      <Menu
        id="language-menu"
        aria-labelledby="language-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: i18n.language === "en-US" ? "right" : "left",
        }}
      >
        <MenuItem
          onClick={() => {
            changeLocale(i18n.language === "en-US" ? "ar" : "en-US");
            handleClose();
          }}
        >
          {i18n.language === "en-US" ? "العربية" : "English"}
        </MenuItem>
      </Menu>
    </>
  );
}
