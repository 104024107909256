import { Edit, Delete } from "@mui/icons-material";
import {
  Typography,
  Button,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SectionHeader from "../../components/section_header";
import SpiningLogo from "../../components/spining_logo";
import { TextInput } from "../../components/text_input";
import {
  InsuranceServiceType,
  MODE,
  SERVER_TYPE,
  SubDescription,
  TYPE,
  User,
} from "../../const";
import i18n from "../../i18n";
import LocaleContext from "../../LocaleContext";
import {
  deleteRequest,
  getRequest,
  handleApiClientError,
  postRequest,
  putRequest,
} from "../../network/api-client";
import {
  flexableParentBoxSX,
  headerActionButtonStyle,
  parentBoxStyle,
} from "../../styles";

const AddOrEditInsuranceService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const [insuranceService, setInsuranceService] =
    useState<InsuranceServiceType>();
  const [mode, setMode] = useState<MODE>(serviceId ? MODE.EDIT : MODE.ADD);
  const [subDescriptionMode, setSubDesciptionMode] = useState<MODE>(MODE.SHOW);
  const { register, handleSubmit, reset } = useForm();
  const {
    register: subDescriptopnRegister,
    handleSubmit: subDescriptopnHandleSubmit,
    reset: subDescriptopnReset,
  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);
  const [subDescription, setSubDescription] = useState<
    SubDescription | undefined
  >(undefined);
  const [isServiceSubmitting, setIsServiceSubmitting] = useState(false);
  const [isSubDescriptionSubmitting, setIsSubDescriptionSubmitting] =
    useState(false);
  const { type } = useContext(LocaleContext);
  const [subDescriptionList, setSubDesciptionList] = useState<
    {
      title: string;
      titleAr: string;
      description: string;
      descriptionAr: string;
    }[]
  >([]);

  const [openSubDescriptionDialog, setOpenSubDescriptionDialog] =
    useState(false);

  const handleCloseSubDescriptionDialog = () => {
    setOpenSubDescriptionDialog(false);
  };

  const onSubmit = async (data: any) => {
    setIsServiceSubmitting(true);
    if (!data.type) {
      data.type =
        type === TYPE.INDIVIDUALS
          ? TYPE.INDIVIDUALS.toLowerCase()
          : TYPE.CORPORATES.toLowerCase();
    }
    const server_type =
      data.type === TYPE.INDIVIDUALS.toLowerCase() ||
      data.type === "الأفراد" ||
      data.type === "individual"
        ? SERVER_TYPE.INDIVIDUALS
        : SERVER_TYPE.CORPORATES;
    data = { ...data, type: server_type };
    const formData = new FormData();
    formData.append("type", data.type);
    selectedImage != null && formData.append("image", selectedImage);
    formData.append("title", data.title);
    formData.append("titleAr", data.titleAr);
    formData.append("mainDescriptionEn", data.mainDescriptionEn);
    formData.append("mainDescriptionAr", data.mainDescriptionAr);

    Swal.fire({
      icon: "info",
      title: t("are-you-sure"),
      text: t("will-add") + data.title,
      confirmButtonText: t("ok"),
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (mode === MODE.ADD) {
          await postRequest("products", formData, true).then(
            (response) => {
              Swal.fire({
                title: response.detail as string,
                text: t("add-sub-descriptions"),
                icon: "success",
                background: "#fff",
                color: "#000",
                showCancelButton: true,
                cancelButtonText: t("cancel"),
                confirmButtonText: t("ok"),
              }).then((result) => {
                if (result.isConfirmed) {
                  setIsServiceSubmitting(false);
                  window.location.href =
                    "/admin/edit-insurance-service/" + response.product.id;
                } else if (
                  result.isDenied ||
                  result.dismiss ||
                  result.isDismissed
                ) {
                  setIsServiceSubmitting(false);
                  navigate("/");
                }
              });
            },
            (error) => {
              handleApiClientError(error);
              setIsServiceSubmitting(false);
            }
          );
        } else if (mode === MODE.EDIT) {
          await putRequest(
            "products/updateOne/" + serviceId,
            formData,
            true
          ).then(
            (response) => {
              Swal.fire({
                title: "",
                text: response.detail as string,
                icon: "success",
                background: "#fff",
                color: "#000",
                confirmButtonText: t("ok"),
              }).then((result) => {
                if (result.isConfirmed) {
                  setIsServiceSubmitting(false);
                  navigate("/");
                }
              });
            },
            (error) => {
              handleApiClientError(error);
              setIsServiceSubmitting(false);
            }
          );
        }
      }
    });
  };

  const subDescriptopnOnSubmit = async (data: any) => {
    setIsSubDescriptionSubmitting(true);
    console.log(
      "te: " +
        data.title +
        ", ta: " +
        data.titleAr +
        ", de: " +
        data.description +
        ", da: " +
        data.descriptionAr
    );
    subDescriptionMode === MODE.ADD
      ? await postRequest("products/description", {
          ...data,
          productId: serviceId,
        }).then((res) => {
          if (res) {
            Swal.fire({
              title: "",
              text: res.detail as string,
              icon: "success",
              background: "#fff",
              color: "#000",
              confirmButtonText: t("ok"),
            }).then((result) => {
              if (result.isConfirmed) {
                fetchInsuranceService();
                setSubDesciptionMode(MODE.SHOW);
                setIsSubDescriptionSubmitting(false);
              }
            });
          }
        })
      : await putRequest(
          "products/description/" + subDescription?.id,
          data
        ).then((res) => {
          if (res) {
            Swal.fire({
              title: "",
              text: res.detail as string,
              icon: "success",
              background: "#fff",
              color: "#000",
              confirmButtonText: t("ok"),
            }).then((result) => {
              if (result.isConfirmed) {
                fetchInsuranceService();
                setSubDesciptionMode(MODE.SHOW);
                setIsSubDescriptionSubmitting(false);
              }
            });
          }
        });
    setSubDescription(undefined);
  };

  const fetchInsuranceService = async () => {
    await getRequest("products/getOne/" + serviceId).then((res) => {
      setInsuranceService(res.product);
      console.log("type: ---------------- " + res.product.type);
      reset({
        type: res.product.type,
        title: res.product.title,
        titleAr: res.product.titleAr,
        mainDescriptionEn: res.product.mainDescriptionEn,
        mainDescriptionAr: res.product.mainDescriptionAr,
      });
    });
  };

  useEffect(() => {
    mode === MODE.EDIT && fetchInsuranceService();
  }, []);

  return (
    <Box sx={{ ...parentBoxStyle, height: "auto" }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          //{ xs: "100%", sm: "100%", md: "100%", lg: "50%" },
        }}
      >
        <SectionHeader
          title={
            mode === MODE.ADD
              ? t("add-new-insurance-service")
              : t("edit-insurance-service")
          }
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            title={t("service-type")}
            type="string"
            required
            list={[
              { name: t(TYPE.INDIVIDUALS.toLowerCase()), code: 1 },
              { name: t(TYPE.CORPORATES.toLowerCase()), code: 2 },
            ]}
            optionValue={{
              name:
                type === TYPE.INDIVIDUALS
                  ? t(TYPE.INDIVIDUALS.toLowerCase())
                  : t(TYPE.CORPORATES.toLowerCase()),
              code: type === TYPE.INDIVIDUALS ? 1 : 2,
            }}
            registeration={{
              ...register("type"),
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              width: "100%",
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "49%" } }}>
              <TextInput
                title={t("service-title-en")}
                type="string"
                required
                value={insuranceService?.title}
                registeration={{
                  ...register("title"),
                }}
                errorMessage={t("enter-en-title")}
              />
            </Box>
            <Divider sx={{ width: "2%" }} />
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "49%" } }}>
              <TextInput
                title={t("service-title-ar")}
                type="string"
                required
                value={insuranceService?.titleAr}
                registeration={{
                  ...register("titleAr"),
                }}
                errorMessage={t("enter-ar-title")}
              />
            </Box>
          </Box>
          <Box
            style={{
              width: "100%",
              height: "25vh",
              backgroundColor: "ButtonShadow",
              backgroundImage: selectedImage
                ? `url(${URL.createObjectURL(selectedImage)})`
                : `url(${insuranceService?.image_url})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              marginBottom: "1.25rem",
              paddingBottom: "0.8rem",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            {!selectedImage && (
              <Button variant="outlined" component="label">
                {t("add-photo")}
                <input
                  type="file"
                  name="myImage"
                  hidden
                  onChange={(event: any) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                  }}
                />
              </Button>
            )}
            {selectedImage && (
              <Button
                variant="outlined"
                sx={{ position: "relative", bottom: "0rem" }}
                onClick={() => setSelectedImage(null)}
              >
                {t("remove")}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              width: "100%",
              height: { xs: "12cm", sm: "12cm", md: "5.5cm" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "49%" },
                height: { xs: "6cm", sm: "6cm", md: "3cm" },
              }}
            >
              <TextInput
                title={t("desciption-in-en")}
                type="string"
                // required
                multiLine={true}
                rows={5}
                value={insuranceService?.mainDescriptionEn}
                registeration={{
                  ...register("mainDescriptionEn"),
                }}
                errorMessage={t("enter-en-description")}
              />
            </Box>
            <Divider sx={{ width: "2%" }} />
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "49%" },
                height: { xs: "6cm", sm: "6cm", md: "3cm" },
              }}
            >
              <TextInput
                title={t("desciption-in-ar")}
                type="string"
                // required
                multiLine={true}
                rows={5}
                value={insuranceService?.mainDescriptionAr}
                registeration={{
                  ...register("mainDescriptionAr"),
                }}
                errorMessage={t("enter-ar-description")}
              />
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            sx={{ mt: "1rem" }}
          >
            {isServiceSubmitting ? (
              <SpiningLogo height="20px" />
            ) : mode === MODE.ADD ? (
              t("add-new-insurance-service")
            ) : (
              t("edit-insurance-service")
            )}
          </Button>
        </form>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          m: "1rem 0 0 0",
          width: "100%",
        }}
      >
        {mode === MODE.EDIT && (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setSubDesciptionMode(MODE.ADD);
                setOpenSubDescriptionDialog(true);
              }}
            >
              {t("add-sub-description")}
            </Button>
            {(subDescriptionMode === MODE.ADD ||
              subDescriptionMode === MODE.EDIT) && (
              <form
                onSubmit={subDescriptopnHandleSubmit(subDescriptopnOnSubmit)}
              >
                <Box>
                  <Box
                    sx={{
                      mt: "1rem",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "49%" }}>
                      <TextInput
                        title={t("sub-desciption-title-in-en")}
                        type="string"
                        required
                        value={subDescription?.title}
                        registeration={{
                          ...subDescriptopnRegister("title"),
                        }}
                        errorMessage={t("enter-en-title")}
                      />
                    </Box>
                    <Divider sx={{ width: "2%" }} />
                    <Box sx={{ width: "49%" }}>
                      <TextInput
                        title={t("sub-desciption-title-in-ar")}
                        type="string"
                        required
                        value={subDescription?.titleAr}
                        registeration={{
                          ...subDescriptopnRegister("titleAr"),
                        }}
                        errorMessage={t("enter-ar-title")}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "5cm",
                    }}
                  >
                    <div style={{ width: "49%" }}>
                      <TextInput
                        title={t("desciption-in-en")}
                        type="string"
                        required
                        multiLine={true}
                        rows={4}
                        value={subDescription?.description}
                        registeration={{
                          ...subDescriptopnRegister("description"),
                        }}
                        errorMessage={t("enter-en-description")}
                      />
                    </div>
                    <Divider sx={{ width: "2%" }} />
                    <div style={{ width: "49%" }}>
                      <TextInput
                        title={t("desciption-in-ar")}
                        type="string"
                        required
                        multiLine={true}
                        rows={4}
                        value={subDescription?.descriptionAr}
                        registeration={{
                          ...subDescriptopnRegister("descriptionAr"),
                        }}
                        errorMessage={t("enter-ar-description")}
                      />
                    </div>
                  </Box>
                </Box>

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  {isSubDescriptionSubmitting ? (
                    <SpiningLogo height="20px" />
                  ) : subDescriptionMode === MODE.ADD ? (
                    t("add-sub-description")
                  ) : (
                    t("edit-sub-description")
                  )}
                </Button>
              </form>
            )}
          </>
        )}
        {insuranceService?.description?.map((subDescription) => (
          <Box sx={{ width: "100%" }}>
            <SectionHeader
              title={
                i18n.language === "en-US"
                  ? subDescription?.title
                  : subDescription?.titleAr
              }
              adminActions={[
                <IconButton
                  style={headerActionButtonStyle}
                  onClick={() => {
                    setSubDesciptionMode(MODE.EDIT);
                    setSubDescription(subDescription);
                    subDescriptopnReset({
                      title: subDescription.title,
                      titleAr: subDescription.titleAr,
                      description: subDescription.description,
                      descriptionAr: subDescription.descriptionAr,
                    });
                    // setOpenSubDescriptionDialog(true);
                  }}
                >
                  <Edit />
                </IconButton>,
                <IconButton
                  style={headerActionButtonStyle}
                  onClick={async () => {
                    await deleteRequest(
                      "products/description/" + subDescription?.id
                    ).then((res) => {
                      if (res) {
                        Swal.fire({
                          title: "",
                          text: res.detail as string,
                          icon: "success",
                          background: "#fff",
                          color: "#000",
                          confirmButtonText: t("ok"),
                        }).then((result) => {
                          fetchInsuranceService();
                        });
                      }
                    });
                  }}
                >
                  <Delete />
                </IconButton>,
              ]}
            />
            <Typography variant="subtitle1" style={{ whiteSpace: "pre-line" }}>
              {i18n.language === "en-US"
                ? subDescription?.description
                : subDescription?.descriptionAr}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AddOrEditInsuranceService;
