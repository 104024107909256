import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpertiseLogo from "../logo.svg";

const WrongPath = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "40vh",
          width: "40vh",
          paddingTop: "11em",
          backgroundImage: `url(${ExpertiseLogo})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Typography variant="h3">{t("404-title")}</Typography>
      <Typography variant="h5">
        go to <a href="/">Home</a>
      </Typography>
    </div>
  );
};

export default WrongPath;
