import "../App.css";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import InsuranceService from "../components/insurance_service";
import SectionHeader from "../components/section_header";
import { isAdmin, SERVER_TYPE, TYPE } from "../const";
import i18n from "../i18n";
import {
  flexableParentBoxSX,
  headerActionButtonStyle,
  homeBoxSx,
  homeInsuranceSX,
  style,
} from "../styles";
import { deleteRequest, getRequest } from "../network/api-client";
import LocaleContext from "../LocaleContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import bgp from "../images/hbgp.png";
import image1En from "../images/carousel-en/image1En.png";
import image2En from "../images/carousel-en/image2En.png";
import image3En from "../images/carousel-en/image3En.png";
import image1Ar from "../images/carousel-ar/image1Ar.png";
import image2Ar from "../images/carousel-ar/image2Ar.png";
import image3Ar from "../images/carousel-ar/image3Ar.png";
import comp1 from "../images/comps/first-takaful.png";
import comp2 from "../images/comps/aman.png";
import comp3 from "../images/comps/ilaf.png";
import comp4 from "../images/comps/boubyan.svg";
import comp5 from "../images/comps/tazar.png";
import comp6 from "../images/comps/wethaq.png";
import comp7 from "../images/comps/zamzam.png";
import comp8 from "../images/comps/kuwait-takaful.png";
import comp9 from "../images/comps/kiti.jpg";
import comp10 from "../images/comps/boubyan.svg";
import comp11 from "../images/comps/dar-alsalam.png";
import Carousel from "react-material-ui-carousel";
import getTheme from "../components/layout";
import LoadingBox from "../components/loading_box";
import Marquee from "react-fast-marquee";

export default function Home() {
  const type = sessionStorage.getItem("type");
  const [insuranceServices, setInsuranceServices] = useState<any>([]);
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = getTheme();
  const mediaQueryDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const mediaQueryUpMD = useMediaQuery(theme.breakpoints.up("md"));

  const sliderImages = [
    {
      name: "image1",
      imgEn: image1En,
      imgAr: image1Ar,
    },
    {
      name: "image2",
      imgEn: image2En,
      imgAr: image2Ar,
    },
    {
      name: "image3",
      imgEn: image3En,
      imgAr: image3Ar,
    },
  ];

  const comps = [
    { image: comp1 },
    { image: comp2 },
    { image: comp3 },
    { image: comp4 },
    { image: comp5 },
    { image: comp6 },
    { image: comp7 },
    { image: comp8 },
    { image: comp10 },
    { image: comp11 },
    { image: comp9 },
  ];

  const fetchInsuranceServices = async () => {
    setIsServicesLoading(true);
    const prodType =
      type === TYPE.INDIVIDUALS
        ? SERVER_TYPE.INDIVIDUALS
        : SERVER_TYPE.CORPORATES;
    await getRequest("products/" + prodType).then(
      (res) => {
        setInsuranceServices(res.products);
        setIsServicesLoading(false);
      },
      (error) => {
        setIsServicesLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchInsuranceServices();
  }, []);

  const handleAddNewInsuranceService = () => {
    window.location.href = "/admin/add-insurance-service";
  };

  return (
    <Box>
      <Carousel
        animation="slide"
        autoPlay={true}
        duration={300}
        swipe={true}
        indicators={false}
        indicatorContainerProps={{
          style: { zIndex: "400", position: "absolute", bottom: "3%" },
        }}
      >
        {sliderImages.map((sliderImage) => (
          <Box
            component="img"
            sx={{
              width: "100%",
              objectFit: "cover",
            }}
            alt={sliderImage.name}
            src={
              i18n.language === "en-US" ? sliderImage.imgEn : sliderImage.imgAr
            }
          />
        ))}
      </Carousel>
      <Box
        sx={{
          ...homeBoxSx,
          margin: {
            xs: "1rem 1rem 3rem 1rem",
            sm: "1rem 1rem 3rem 1rem",
            md: "1rem 1rem 3rem 1rem",
            lg: "1rem 5rem 3rem 5rem",
          },
        }}
      >
        <SectionHeader
          title={
            i18n.language === "en-US"
              ? t(`${type?.toLowerCase()}`) + " " + t("inurance-services")
              : t("inurance-services") + " " + t(`${type?.toLowerCase()}`)
          }
          adminActions={[
            <IconButton
              style={headerActionButtonStyle}
              onClick={handleAddNewInsuranceService}
            >
              <Add />
            </IconButton>,
          ]}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row-reverse" },
            justifyContent: "space-around",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: insuranceServices.length === 0 ? "auto" : undefined,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isServicesLoading ? (
              <LoadingBox />
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    p: "3rem",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {insuranceServices.map((service: any, i: number) => (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "row-reverse",
                        },
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        onClick={() => navigate("/" + service.id)}
                        sx={{
                          width: "179px",
                          height: "112px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundImage: `url(${bgp})`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",

                          cursor: "pointer",
                        }}
                      >
                        <Typography variant="h5" textAlign={"center"}>
                          {i18n.language === "en-US"
                            ? service?.title
                            : service?.titleAr}
                        </Typography>
                      </Box>
                      <Divider
                        orientation={
                          mediaQueryDownMD ? "horizontal" : "vertical"
                        }
                        sx={{
                          backgroundColor: "#790000",
                          width: { xs: "50%", sm: "50%", md: "auto" },
                          height: { md: "50%" },
                          placeSelf: "center",
                          display:
                            insuranceServices.length > 1
                              ? i === 0 && mediaQueryUpMD
                                ? "none"
                                : i === insuranceServices.length - 1 &&
                                  mediaQueryDownMD
                                ? "none"
                                : "block"
                              : "none",
                          margin: {
                            xs: "1.5rem 0 1.5rem 0",
                            sm: "1.5rem 0 1.5rem 0",
                            md: undefined,
                          },
                        }}
                      />
                    </Box>
                  ))}
                </Box>
                {insuranceServices.length < 1 && (
                  <Box
                    sx={{
                      height: "100vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isAdmin && (
                      <Box
                        sx={{
                          width: { xs: "70%", sm: "70%", md: "70%", lg: "50%" },
                          p: "2rem",
                          mb: "2rem",
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "column",
                            lg: "row",
                          },
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(152,142,52,0.4)",
                          cursor: "pointer",
                          borderRadius: "5rem",
                        }}
                        onClick={() => navigate("/admin/add-insurance-service")}
                      >
                        <Add
                          sx={{
                            fontSize: {
                              xs: "5rem",
                              sm: "5rem",
                              md: "5rem",
                              lg: "10rem",
                            },
                          }}
                          color="primary"
                        />

                        <Typography variant="h4">
                          {t("press-to-add-service")}
                        </Typography>
                      </Box>
                    )}
                    <Typography variant="h5" textAlign={"center"}>
                      {t("no-insurance-services")}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Typography variant={"h4"} textAlign={"center"}>
        {t("our-partners")}
      </Typography>
      <Marquee
        speed={60}
        gradient={false}
        direction={i18n.language === "en-US" ? "right" : undefined}
        style={{ direction: i18n.language === "ar" ? "ltr" : undefined }}
        pauseOnHover={false}
        pauseOnClick={true}
      >
        <Box
          // className="logos"
          sx={{
            my: "2rem",
            px: "2rem",
            heghit: "150px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {comps.map((comp) => (
            <Box
              sx={{
                mx: "1rem",
                width: "250px",
                minWidth: "250px",
                maxWidth: "250px",
                height: "150px",
                backgroundImage: `url(${comp.image})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          ))}
        </Box>
      </Marquee>
    </Box>
  );
}
