import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { InsuranceServiceType, SERVER_TYPE, TYPE } from "../const";
import i18n from "../i18n";
import LocaleContext from "../LocaleContext";
import {
  deleteRequest,
  getRequest,
  handleApiClientError,
} from "../network/api-client";
import {
  flexableParentBoxSX,
  headerActionButtonStyle,
  parentBoxStyle,
} from "../styles";
import Loading from "./loading";
import LoadingBox from "./loading_box";
import SectionHeader from "./section_header";
import SpiningLogo from "./spining_logo";

const InsuranceService = () => {
  const { t } = useTranslation();
  const { serviceId } = useParams();
  const [service, setService] = useState<InsuranceServiceType>();
  const navigate = useNavigate();
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const type = sessionStorage.getItem("type");
  const [insuranceServices, setInsuranceServices] = useState<any>([]);

  const handleEditService = () =>
    navigate("/admin/edit-insurance-service/" + serviceId);

  const handleDeleteService = () => {
    Swal.fire({
      icon: "warning",
      title: t("are-you-sure"),
      text: t("delete-service"),
      confirmButtonText: t("delete"),
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      background: "#fff",
      color: "#000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteRequest("products/deleteOne/" + serviceId).then(
          (res) => {
            if (res) {
              Swal.fire({
                title: "",
                text: res.detail as string,
                icon: "success",
                background: "#fff",
                color: "#000",
                confirmButtonText: t("ok"),
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/");
                }
              });
            }
          },
          (error) => {}
        );
      }
    });
  };

  const fetchInsuranceService = async () => {
    setIsServiceLoading(true);
    const res = await getRequest("products/getOne/" + serviceId);
    setService(res.product);
    setIsServiceLoading(false);
  };

  useEffect(() => {
    fetchInsuranceService();
    fetchInsuranceServices();
  }, []);
  const fetchInsuranceServices = async () => {
    const prodType =
      type === TYPE.INDIVIDUALS
        ? SERVER_TYPE.INDIVIDUALS
        : SERVER_TYPE.CORPORATES;
    await getRequest("products/" + prodType).then(
      (res) => {
        setInsuranceServices(res.products);
      },
      (error) => {
        handleApiClientError(error);
      }
    );
  };

  return isServiceLoading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        ...flexableParentBoxSX,
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row-reverse",
        },
        height: "auto",
      }}
    >
      <Box
        sx={{
          bottom: "0",
          borderRight: i18n.language === "ar" ? "3px solid #FFCB8E" : undefined,
          borderLeft:
            i18n.language === "en-US" ? "3px solid #FFCB8E" : undefined,
          height: "100%",
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
          width: { xs: "100%", sm: "100%", md: "100%", lg: "20%" },
          // backgroundColor: "blue",
          px: "1rem",
          mb: "3rem",
        }}
      >
        <Typography variant="h5">
          {i18n.language === "en-US"
            ? t(`${type?.toLowerCase()}`) + " " + t("inurance-services")
            : t("inurance-services") + " " + t(`${type?.toLowerCase()}`)}
        </Typography>
        <br />
        <Box sx={{ width: "100%", height: "100%" }}>
          {insuranceServices.length > 0 ? (
            insuranceServices.map((service: any) => (
              <Button
                fullWidth
                sx={{ justifyContent: "start", color: "black" }}
                variant="text"
                href={"/" + service.id}
              >
                {i18n.language === "en-US" ? service.title : service.titleAr}
              </Button>
            ))
          ) : (
            <LoadingBox />
          )}
        </Box>
      </Box>
      <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "70%" } }}>
        <SectionHeader
          title={i18n.language === "en-US" ? service?.title : service?.titleAr}
          adminActions={[
            <IconButton
              style={headerActionButtonStyle}
              onClick={handleEditService}
            >
              <Edit />
            </IconButton>,
            <IconButton
              style={headerActionButtonStyle}
              onClick={handleDeleteService}
            >
              <Delete />
            </IconButton>,
          ]}
          dividerHeight={"0.1rem"}
        />
        <Box sx={{ width: "100%" }}>
          <img
            src={service?.image_url}
            alt={service?.title + " image"}
            width={"100%"}
            style={{ maxHeight: "25vh" }}
          />
        </Box>
        <br />
        <Typography
          variant="body1"
          style={{ whiteSpace: "pre-line" }}
          noWrap={true}
        >
          {i18n.language === "en-US"
            ? service?.mainDescriptionEn
            : service?.mainDescriptionAr}
        </Typography>
        <br />
        {service?.description?.map((description: any) => (
          <Box sx={{ mb: "2rem" }}>
            <Typography variant="h5" sx={{ mb: "0.3rem" }}>
              {i18n.language === "en-US"
                ? description?.title
                : description?.titleAr}
            </Typography>
            <Typography
              variant="body1"
              style={{ whiteSpace: "pre-line" }}
              noWrap={true}
            >
              {i18n.language === "en-US"
                ? description?.description
                : description?.descriptionAr}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InsuranceService;
