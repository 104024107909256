import { Box } from "@mui/material";
import SpiningLogo from "./spining_logo";

const LoadingBox = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SpiningLogo height="50px" />
  </Box>
);

export default LoadingBox;
