import i18n from "./i18n";

export const style = {
  marginTop: "1em",
  marginBottom: "1.6em",
  fontFamily: "Noto Sans Arabic",
  fontStyle: "regular",
  fontDisplay: "swap",
  fontWeight: 400,
  width: "100%",
  borderRadius: "2em",
  height: "50px",
};

export const parentBoxStyle = {
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  // px: { xs: "1rem", sm: "1rem" },
  padding: {
    xs: "3rem 1rem 3rem 1rem",
    sm: "3rem 1rem 3rem 1rem",
    md: "3rem 1rem 3rem 1rem",
    lg: "3rem 5rem 3rem 5rem",
    xl: "3rem 5rem 3rem 5rem",
  },
};

export const permissionsControllerGridStyle = {
  borderRadius: { xs: "0", sm: "0", md: "0", lg: "1.5em" },
  padding: "2%",
  margin: "1%",
  background: "white",
  boxShadow: {
    md: undefined,
    xs: undefined,
    sm: undefined,
    lg: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
  },
};

export const permissionControllerListTile = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "5px",
  justifyContent: "space-between",
};
export const flexableParentBoxSX = {
  display: "flex",
  flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" },
  justifyContent: "space-between",
  width: "100%",
  // m: "3rem 0 3rem 0",
  padding: {
    xs: "3rem 1rem 3rem 1rem",
    sm: "3rem 1rem 3rem 1rem",
    md: "3rem 1rem 3rem 1rem",
    lg: "3rem 5rem 3rem 5rem",
    xl: "3rem 5rem 3rem 5rem",
  },
};

export const homeBoxSx = {
  height: "auto",
  display: "flex",
  flexDirection: "column",
  margin: {
    xs: "3rem 1rem 3rem 1rem",
    sm: "3rem 1rem 3rem 1rem",
    md: "3rem 1rem 3rem 1rem",
    lg: "3rem 5rem 3rem 5rem",
    xl: "3rem 5rem 3rem 5rem",
  },
  p: {
    xs: "0 1rem 0 1rem",
    sm: "0 1rem 0 1rem",
    md: "0",
  },
};

export const userListTileStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#796328",
  color: "white",
  p: "1rem",
  my: "0.5rem",
  borderRadius: "2rem",
};

export const headerActionButtonStyle = {
  margin:
    i18n.language === "en-US"
      ? "0.5rem 0.5rem 0.5rem 0"
      : "0.5rem 0 0.5rem 0.5rem",
  color: "#931A25",
  backgroundColor: "transparent",
  border: "2px solid #FFCB8E",
  borderRadius: "100%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  height: "3rem",
};

export const IconButtonSX = {
  margin:
    i18n.language === "en-US"
      ? "0.5rem 0.5rem 0.5rem 0"
      : "0.5rem 0 0.5rem 0.5rem",
  color: "#FFCB8E",
  backgroundColor: "transparent",
  border: "2px solid #FFCB8E",
  borderRadius: "100%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
};

export const text_input_box_style = {
  width: {
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "47.5%",
  },
};

export const gridStyle = {
  paddingX: "1rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const homeInsuranceSX = {
  backgroundColor: "gray",
  p: "2rem",
  width: {
    xs: "100%",
    sm: "100%",
    md: "40%",
    lg: "25%",
    xl: "25%",
  },
  borderRadius: "3rem",
};

export const branchPropsSX = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "row",
    justifyContent: "space-between",
  },
  width: "100%",
};

export const branchPropsItemSX = {
  border: "2px solid #796328",
  p: "1rem",
  width: { xs: "100%", sm: "100%", md: "25%" },
  m: {
    xs: "0.5rem 0 0.5rem 0",
    sm: "0.5rem 0 0.5rem 0",
    md: "0 0.5rem 0 0.5rem",
  },
  borderRadius: "1rem",
};
